import React, {useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom"
import { wildroselogo1, login1} from "../assets";
import {useNavigate} from "react-router";
import { urls } from "../constants";
import Swal from 'sweetalert2';

const Signup = () => {
    
    const [user,
        setUser] = useState({company_name: "", email: "", password: "", re_password: "", is_superuser: false});

    let nav = useNavigate()

    const addUser = (e) => {
        e.preventDefault();
        axios
            .post(`${urls.url}/auth/users/`, user)
            .then((resp) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Form submitted successfully, check your email!',
                  });
                  nav('/login')
            })
            .catch((err) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong!',
                  });
            });
    };

    return (
        <div className="bg-white">
            {/* <Header/> */}
            <div className=" mt-0">
                <div className="row">
                 
                    <div className="col-lg-6 col-md-6 pr-0"
                    style={{
              backgroundImage: `url(${login1})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
                    >
               
                    {/* <img
              src={login}
              alt=""
              className="img-fluid mx-auto d-block mt-0 mb-0"  // Added Bootstrap classes
              style={{ maxWidth: "100%" }}
            /> */}
                            {/* {err === true
                        ? (
                            <div class="alert alert-danger" role="alert">
                                Something went wrong!
                            </div>
                        )
                        : <div class="alert alert-success" role="alert">
                            Kindly check your email!
                        </div>
} */}
                    </div>
                    

                    <div className="col-lg-6 col-md-6 bg-[#F5F5F5] border-gray-900 shadow-lg rounded" style={{paddingTop: "300px"}}>
                    <Link to="/">
        <img
          src={wildroselogo1}
          alt="Logo"
          className="img-fluid mx-auto d-block mt-0 mb-0"
          style={{ maxWidth: '51%', position: 'absolute', top: '10%', left: '23%', transform: 'translate(-50%, -50%)' }}
        />
      </Link>
                    <h1 className="text-[#005da4] text-4xl text-center mb-5">Register</h1>
                        <form onSubmit={addUser} className="p-10">
                            <div class="grid gap-6 mb-6 md:grid-cols-1 ">

                                <div>
                                    <label
                                        for="company"
                                        class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-black text">
                                        Company
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                        type="text"
                                        id="company"
        //                                 class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
        //  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
        //   dark:focus:border-blue-500"
                                        placeholder="Company Name"
                                        name="company_name"
                                        onChange={(e) => setUser({
                                        ...user,
                                        company_name: e.target.value
                                    })}
                                        value={user.company_name}
                                        required/>
                                </div>

                            </div>
                            <div class="grid gap-6 mb-6 md:grid-cols-1">

                                <div>
                                    <label
                                        for="company"
                                        class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-black">
                                        Email
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                        type="email"
                                        id="password"
            //                             class="text-white bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
            // focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
            //  dark:focus:border-blue-500"
                                        placeholder="Email"
                                        name="email"
                                        onChange={(e) => setUser({
                                        ...user,
                                        email: e.target.value
                                    })}
                                        value={user.email}
                                        required/>
                                </div>

                            </div>
                            <div class="grid gap-6 mb-6 md:grid-cols-1">

                                <div>
                                    <label
                                        for="company"
                                        class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-black">
                                        Password
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                        type="password"
                                        id="password"
        //                                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
        //  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
        //   dark:focus:border-blue-500"
                                        placeholder="•••••••••"
                                        name="password"
                                        onChange={(e) => setUser({
                                        ...user,
                                        password: e.target.value
                                    })}
                                        value={user.password}
                                        required/>
                                </div>

                            </div>
                            <div class="grid gap-6 mb-6 md:grid-cols-1">

                                <div>
                                    <label
                                        for="company"
                                        class="text-left block mb-2 text-lg font-medium text-gray-900 dark:text-black">
                                        Confirm password
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                        type="password"
                                        id="confirm_password"
        //                                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
        //  focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
        //   dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="•••••••••"
                                        required
                                        name="re_password"
                                        onChange={(e) => setUser({
                                        ...user,
                                        re_password: e.target.value
                                    })}
                                        value={user.re_password}/>
                                </div>

                            </div>

                            <button
                                type="submit"
                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md w-full md:w-auto px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Submit
                            </button>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default Signup;
