import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { whiteLogo, wildroselogo2 } from '../assets'
import { Link } from 'react-router-dom'
import './MyJobs.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom'
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { Footer } from '../components'
import { urls } from '../constants'

const MyJobs = () => {
  const [user, setUser] = useState({})
  const [job, setJob] = useState([])
  const [industry, setIndustry] = useState([]);
  const company_name = user?.company_name
  let data = []
  let userId = localStorage.getItem('user_id')
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchLoggedInUser = () => {
    axios.get(`${urls.url}/users/${userId}`).then(resp=>{
      
      setUser(resp?.data)
      axios.get(`${urls.url}/filter-by-company-name/?company_name=${resp?.data?.company_name}`).then(res=>{
      data = res?.data
      setJob(data)
    }).catch(err=>{
      console.log(err)
    })
    }).catch(err=>{
      console.log(err)
    })
  }

  let move= useNavigate()
let accessToken = sessionStorage.getItem("access")

if(accessToken===null){
  move('/login')
}
  const [jobUpdate, setJobUpdate] = useState({
    job_title: "",
    location: "",
    industry: "",
    description: "",
    qualification: "",
  });

  const fetchAllIndustries = () => {
    axios
      .get(`${urls.url}/industries/`)
      .then((resp) => {
        setIndustry(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

useEffect(()=>{
  fetchAllIndustries()
  fetchLoggedInUser()
},[])

const systemLogout = () => {
  sessionStorage.removeItem("access")
}

  const addJob = (e) => {
    const updatedJob = {
      ...jobUpdate,
      company_name: company_name,
    };
    e.preventDefault();
    axios
      .post(`${urls.url}/all-jobs/`, updatedJob)
      .then((resp) => {
        console.log(resp);
        handleClose()
        fetchLoggedInUser()
      })
      .catch((err) => {
        console.warn(err + "ziku error");
      });
  };




      const nav = useNavigate()


  return (

    <div className='mb-40 bg-white'>
      <nav className="bg-[#005da4]">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto ">
                <Link to="/" className="flex items-center">
                    <img src={wildroselogo2} className="h-24 mr-3" alt="Wildrose Logo" />
                </Link>
    
      {/*<span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">WILD ROSE Logo</span>*/}
  
      <div className="flex md:order-2">
      
      <Link to='/'>
                        <div className='flex'>
                            <button onClick={systemLogout} className='btn btn-success btn-lg clientlgn pl-5 pr-5'>Logout</button>
                        </div>
                        </Link>
      <button data-collapse-toggle="navbar-cta" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-cta" aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
  </div>
  
  <div className="items-start justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-cta">
  <ul className="flex flex-col font-medium p-4 md:p-0 rounded-lg bg-[#005da4] md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-[#005da4] dark:bg-gray-800 md:dark:bg-gray-900] ">
                        <li>
                            <Link
                                to="/"
                                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500 text-xl hover:no-underline hover:scale-110"
                                aria-current="page"
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/jobs"
                                className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
                            >
                                Jobs
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
                            >
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/employers"
                                className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
                            >
                                Employers
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className="block py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 text-xl hover:no-underline hover:scale-110"
                            >
                                Contact
                            </Link>
                        </li>
                        <li>
                      
                        </li>
                        
                    </ul>
    
  </div>
  </div>
  
</nav>


        <div className='pt-20 bg-white mb-40'>
        <div className='valuebody container pt-0 mt-0 text-left'>
        <h1 className='text-lg text-[#005da4] valueH1'> Welcome {company_name} </h1>          
        </div>
              
        <Button variant="primary" onClick={handleShow}>
        Post new job
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Post new job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="w-full max-w-xl mx-auto">
        <form
                onSubmit={addJob}
                className="bg-slate-300 shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{ height: "100%" }}
              >
                <h1 className="text-4xl text-center mb-5">Add Job</h1>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="username"
                  >
                    Job Title
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder="Job Title"
                    name="job_title"
                    onChange={(e) =>
                      setJobUpdate({
                        ...jobUpdate,
                        job_title: e.target.value,
                      })
                    }
                    value={jobUpdate.job_title}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Location
                  </label>
                  <input
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="text"
                    placeholder="Location"
                    name="location"
                    onChange={(e) =>
                      setJobUpdate({
                        ...jobUpdate,
                        location: e.target.value,
                      })
                    }
                    value={jobUpdate.location}
                    required
                  />
                  <p className="text-red-500 text-xs italic">Enter location.</p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Description
                  </label>
                  <MarkdownEditor
  value={jobUpdate.description}
  onChange={(e) =>
    setJobUpdate({
      ...jobUpdate,
      description: e.text,  // Use e.text to get the updated text value
    })
  }
  style={{
    height: "200px",
  }}
/>

                  <p className="text-red-500 text-xs italic">
                    Enter description.
                  </p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Qualifications
                  </label>
                  <MarkdownEditor
  value={jobUpdate.qualification}
  onChange={(e) =>
    setJobUpdate({
      ...jobUpdate,
      qualification: e.text,  // Use e.text to get the updated text value
    })
  }
  style={{
    height: "200px",
  }}
/>

                  <p className="text-red-500 text-xs italic">
                    Enter qualification.
                  </p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Industry
                  </label>
                  <select
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Location"
                    value={jobUpdate.industry}
                    onChange={(e) =>
                      setJobUpdate({
                        ...jobUpdate,
                        industry: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select an industry
                    </option>
                    {industry?.map((el) => (
                      <option key={el.id} value={el.industry_name}>
                        {el?.industry_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 text-xs italic">Enter Industry.</p>
                </div>

              </form>

    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addJob}>
  Save Changes
</Button>

        </Modal.Footer>
      </Modal>


      <div>
          
        <div className='jobhove grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto max-w-screen-xl mt-20 '>
         { job?.map((el, index) =>
      <div className={`group group/item singleJob w-[250px] p-[20px] rounded-[10px] shadow-lg shadow-gray-400 hover:bg-blue-500 hover:shadow-lg flex flex-col justify-between slide-and-fade-in animate-fade-in-${index}`}>
             <span className='flex justify-between items-center gap-4'>
                <h1 className='text-[20px] font-bold text-[#005da4] group-hover:text-[#fff]'>
                  {el?.job_title}
                </h1>
                <span className='flex items-center text-[#ccc] gap-1'>Now</span>
              </span>
              <h6 className='text-[#ccc]'>{el?.location}</h6>
            <div className='text-left text-[13px] text-[#959595] pt-[20px] border-t-[2px] mt-[20px] group-hover:text-[#fff]'>
  <ReactMarkdown>{el?.description.substring(0, 100)}</ReactMarkdown>
</div>

           <div className='flex items-center gap-2'>
                <img src={whiteLogo} className='w-[50%]' alt='Logo' />
                <span className='text-[14px] py-[1rem] block group-hover:text-[#fff]'></span>
              </div>
          <button
                onClick={() => {
                  nav(`/more-info/${el?.id}`);
                }}
                className='border-[2px] rounded-[10px] block p-[10px] w-full text-[14px] font-semibold text-[#005da4] group-hover:text-[#fff] transform transition-transform duration-300 hover:scale-105'
              >
                Delete Job
              </button>
      </div>
      )}
      </div>

    </div>
    </div>
    <Footer />
    </div>
    
  )
}

export default MyJobs