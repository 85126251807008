import React, { useState } from 'react';
import { Header, Footer } from '../components';

function Faqs() {
    const [expanded, setExpanded] = useState(null);

    const toggleAccordion = (index) => {
        if (expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index);
        }
    };

    // Define an array of FAQ items with questions and answers
    const faqItems = [
        {
            question: "HOW MUCH DOES WILDROSE PLACEMENT INC CHARGE CANDIDATES FOR RECRUITMENT?",
            answer: "Wildrose Placement does NOT charge candidates for recruitment."
        },
        {
            question: "WHICH DOCUMENTS DO I NEED TO SUBMIT?",
            answer: "The documents depend on the job application being submitted. Our team of professionals will guide you through the type of documents required which we will submit on your behalf. Electronic or original copies of your documents will be requested from you depending on the type of application being made, that is either online or through mail."
        },
        {
            question: "HOW LONG DOES THE PROCESS TAKES?",
            answer: "The time frame of any Wildrose immigration application is dependent on many factors. Our reliable immigration experts will utilize the information provided in your assessment and consultation to build a plan for your situation. Once this plan is determined, we will then be able to give an estimate on the time frame for your application based on the type of application, country of origin, and the complexities of your personal situation."
        },
        {
            question: "HOW MUCH DOES THE PROCESS COST?",
            answer: "A candidate is not required to pay for the recruitment service. However, the immigration costs depend on personal situations and complexities."
        },
        {
            question: "HOW TO APPLY?",
            answer: "Go to https://www.wildroseplacement.com/jobs/"
        }
        // Add more FAQ items here if needed
    ];

    return (
        <>
        <Header />
        <div>
            <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="max-w-2xl mx-auto text-center">
                    <div className='valuebody'>
                        <h1 style={{fontSize: "38px"}}  className="valueH1 text-4xl font-bold leading-tight text-[#005da4] sm:text-3xl lg:text-4xl ">
                            Explore Common Questions
                        </h1>
                        </div>
                    </div>
                    <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                        {faqItems.map((item, index) => (
                            <div
                                key={index}
                                className="transition-all duration-200 bg-white border-1 border-gray-100 shadow-md shadow-blue-300  cursor-pointer hover:bg-gray-50 hover:scale-105"
                            >
                                <button
                                    type="button"
                                    onClick={() => toggleAccordion(index)}
                                    className="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                                >
                                   <div className="valuebody">
                                        <h1 style={{fontSize: "20px"}} className="valueH1 flex font-semibold text-[#005da4] text-left">{item.question}</h1>
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className={`w-6 h-6 text-gray-400 ${
                                            expanded === index ? 'transform rotate-0' : 'transform rotate-[-180deg]'
                                        }`}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                <div
                                    className={`text-left px-4 pb-5 sm:px-6 sm:pb-6 ${
                                        expanded === index ? 'block' : 'hidden'
                                    }`}
                                >
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className=" text-center text-gray-600 textbase mt-9">
                        Still have questions?
                        <span className="text-base cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline">
                           <a style={{fontSize: "20px"}} className="text-red-600 cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline" href='/contact'>Contact our support</a> 
                        </span>
                    </p>
                </div>
            </section>
        </div>
        <Footer />
        </>
    );
}

export default Faqs;
