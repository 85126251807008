import React, { useState } from 'react'
import'./ContactUs.css'
import { wildroselogo2 } from '../assets'
import Header from './Header'
import Footer from './Footer'
import axios from 'axios'
import { urls } from '../constants'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router'

function ContactUs() {
  const [contact, setContact] = useState({
    first_name:'',
    last_name:'',
    email:'',
    message:''
  })

  let nav = useNavigate()

  const sendMessage = (e) => {
    e.preventDefault();
    axios
      .post(`${urls.url}/contacts/`, contact)
      .then((resp) => {
        console.log(resp);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
        });
        

      })
      .catch((err) => {
        
        nav('/jobs')
      });
  };

  return (
    
    <div className='bg-[#fff] mb-40'>
      < Header/>
      <section className="footer_get_touch_outer">
        <div className="container mb-20">
          <h1 className='text-[#005da4] mb-20'>CONTACT US</h1>
          <div className="footer_get_touch_inner grid-70-30">
            <div className="colmun-70 get_form">
              <div className="get_form_inner ">
                <div className="get_form_inner_text">
                  <h3>Get In Touch</h3>
                </div>
                <form onSubmit={sendMessage}>
                  <div className="grid-50-50">
                    <input type="text" placeholder="First Name" name="first_name" onChange={(e) => setContact({ ...contact, first_name: e.target.value })} value={contact.first_name}/>
                    <input type="text" placeholder="Last Name" name="last_name" onChange={(e) => setContact({ ...contact, last_name: e.target.value })} value={contact.last_name}/>
                                  
                  </div>
                  <div className="grid-full">
                  <input className="grid-full" type="email" placeholder="Email" name="email" onChange={(e) => setContact({ ...contact, email: e.target.value })} value={contact.email} />
                    <textarea placeholder="Send us a message" cols={30} rows={10} defaultValue={""} name="message" onChange={(e) => setContact({ ...contact, message: e.target.value })} value={contact.message}/>
                    <input type="submit" defaultValue="Submit"/>
                  </div>
                </form>
              </div>
            </div>
            <div className="colmun-30 get_say_form min-w-[100%]">
              <div className=""> <img src={ wildroselogo2 } alt="" /></div>
              <ul className="get_say_info_sec">
                <li>
                <i className="fab fa-phone fa-20x" />
                  <a href="tel:+17802672387">+1 587 398 2542</a>
                </li>
                <li>
                  <i className="fab fa-linkedin" />
                  <a href="https://www.linkedin.com/company/wildrose-placement/">Our LinkedIn</a>
                </li>
                <li className="emailsm">
                  <i className="fa fa-envelope" />
                  <a href="mailto:info@wildroseplacement.ca">info@wildroseplacement.ca</a>
                </li>
              </ul>
              <ul className="get_say_social-icn">
                <a className='p-0' style={{ textDecoration: 'none' }} target='blank' href="https://www.facebook.com/people/Wildrose-Placement-INC/61552947688017/?mibextid=eHce3h"><li><i className="fab fa-facebook-f" /></li></a>
                <a className='p-0' style={{ textDecoration: 'none' }} target='blank' href="https://twitter.com/WildroseINC01"><li><i className="fab fa-twitter pr-0" /></li></a>         
                <a className='p-0' style={{ textDecoration: 'none' }} target='blank' href="https://instagram.com/wildroseplacementinc?utm_source=qr"><li><i className="fab fa-instagram" /></li></a>        
                <a className='p-0' style={{ textDecoration: 'none' }} target='blank' href="https://t.me/+G0qj66r9suFiZjIx"><li><i className="fab fa-telegram" /></li></a>        
                <a
  className='p-0'
  style={{ textDecoration: 'none' }}
  href={
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? 'https://wa.me/+17802672387'
      : 'https://web.whatsapp.com/send?phone=+17802672387'
  }
  target='_blank'
  rel='noopener noreferrer'
>
  <li><i className="fab fa-whatsapp" /></li>
</a>       
              </ul>
            </div>        
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default ContactUs
