import React, { useState } from 'react'

function Employerfaq() {
    const [expanded, setExpanded] = useState(null);

    const toggleAccordion = (index) => {
        if (expanded === index) {
            setExpanded(null);
        } else {
            setExpanded(index);
        }
    };

    // Define an array of FAQ items with questions and answers
    const faqItems = [
        {
            question: "WHAT IS LMIA?",
            answer: "A Labour Market Impact Assessment (LMIA) is a document that an employer in Canada may need to receive prior to hiring a foreign worker. When a Canadian employer is seeking to hire a foreign worker, it must first be determined if an LMIA is needed or if the job offer is LMIA-exempt before applying for a work permit."
        },
        {
            question: "WHAT IS CLOSED WORK PERMIT?",
            answer: "A closed work permit is issued to a foreign worker to work in a specific position and for the specific employer listed on the LMIA or on the Offer of Employment (OOE) in case a job offer qualifies for an LMIA exemption. Closed or employer-specific work permits authorize temporary foreign workers to work only for a specific employer in a specific position and at a specific location."
        },
        {
            question: "HOW DOES THE PROCESS WORK?",
            answer: `We offer a tailor-made system that is suited and purposely built to meet your labor needs. 
            Our recruiting system is such that it will allow us to pick the right candidate for your business and provide you with key updates in the recruiting timeline. 
            
            Our recruiting process is sure to provide the right fit for your organisation by providing candidates that are well verted and qualified to work in the province and within the specified industry. Our candidates go through several interviews alongside background and educational checks.
            
           Our immigration experts facilitate a seamless and hustle free procedure tailored to your business needs. Throughout the transition we guarantee compliance with both federal and provincial regulations, ensuring swift and effective application processing. 
           We equip your new hires with essential guidance for a seamless integration into your workplace and community. This encompasses pre arrival cultural orientation gathering necessary employment documents and offering ongoing support during their initial 3 months. 
            `
        },
        {
            question: "HOW DO YOU CONTACT US?",
            answer: "To learn more about how your business can leverage international recruitment to help grow your business, please sign up for your free consultation here: https://www.wildroseplacement.com/employers"
        },
        {
            question: "WHERE DOES WILDROSE GET RECRUITS?",
            answer: "Wildrose is getting its talents from all over the world."
        },
      
        // Add more FAQ items here if needed
    ];

    return (
        <>
       
        <div className=''>
            <section className="py-10 bg-gray-50 sm:py-16 lg:py-24 bg-white">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className=" max-w-2xl mx-auto text-center">
                        <div className='valuebody'>
                        <h1 style={{fontSize: "38px"}}  className="valueH1 text-4xl font-bold leading-tight text-[#005da4] sm:text-3xl lg:text-4xl ">
                            Explore Common Questions
                        </h1>
                        </div>
                    </div>
                    <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16 ">
                        {faqItems.map((item, index) => (
                            <div
                                key={index}
                                className="transition-all duration-200 bg-white border-1 border-gray-100 shadow-md shadow-red-300  cursor-pointer hover:bg-gray-50 hover:scale-105 "
                            >
                                <button
                                    type="button"
                                    onClick={() => toggleAccordion(index)}
                                    className="flex  justify-between w-full px-4 py-5 sm:p-6"
                                >
                                    <div className="valuebody">
                                        <h1 style={{fontSize: "24px"}} className="valueH1 flex font-semibold text-[#005da4] text-left">{item.question}</h1>
                                    </div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        className={`w-6 h-6 text-gray-400 ${
                                            expanded === index ? 'transform rotate-0' : 'transform rotate-[-180deg]'
                                        }`}
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                <div
                                    className={`text-left px-4 pb-5 sm:px-6 sm:pb-6 ${
                                        expanded === index ? 'block' : 'hidden'
                                    }`}
                                >
                                    {item.answer}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className=" text-center text-gray-600 textbase mt-9">
                        Still have questions?
                        <span className="text-base cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline">
                           <a style={{fontSize: "20px"}} className="text-red-600 cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline" href='/contact'>Contact our support</a> 
                        </span>
                    </p>
                </div>
            </section>
        </div>
     
        </>
    );
}

export default Employerfaq
