import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
  engineering,
  health2,
  IT1,
  hr,
  truck,
  trades,
  fast,
  account,
} from '../assets';
import axios from 'axios'
import { urls } from '../constants';

const Gallery = () => {


  const [industry, setIndustry] = useState([])

  const fetchAllIndustries = () => {
    axios.get(`${urls.url}/industries/`).then(res=>{
      setIndustry(res?.data)
      console.log(res?.data)
    }).catch(err=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    fetchAllIndustries()
  }, [])


  return (
    <>
    <span className='valuebody'><h1 className='valueH1 text-center text-[#005da4] text-6xl font-semibold pt-20 pb-10'>
      
        EXPLORE OUR INDUSTRIES AND SECTORS
      </h1></span>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-4 mx-auto max-w-screen-xl mt-10'>
        
        {
          
          industry?.map(el=>(
            <Link key={el?.id} to={`industry/${el?.id}/${el?.industry_name}`}>
          <figure className='relative cursor-pointer overflow-hidden shadow-lg shadow-xl shadow-[gray] transform hover:scale-105 transition-transform duration-300 ease-out'>
            <img
              className='h-56 w-full object-cover rounded-lg'
              src={el?.image}
              alt='industry_image'
            />
            <figcaption className='absolute px-4 text-center text-4xl font-semibold text-white bg-gray-500 bg-opacity-50 bottom-6'>
              <p>{el?.industry_name}</p>
            </figcaption>
          </figure>
        </Link>
          ))
        }
              </div>
    </>
  );
};

export default Gallery;
