import React from "react";
import { TransparentTabs } from "./Tabs";
import { ellen, gchikono, paida1, shepiro1, vndoro } from "../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const linkShepherd = <a href='https://www.linkedin.com/in/shepherd-murenje-9a7812a3/' target="_blank"><i class='fab fa-linkedin-in' style={{fontSize:'24px', color: '#005da4'}}></i></a>
const linkVimbayi = <a href='https://www.linkedin.com/in/vimbayi-n-05a279199/' target="_blank"><i class='fab fa-linkedin-in' style={{fontSize:'24px', color: '#005da4'}}></i></a>
const linkEllen = <a href='linkedin.com/in/wildrose-placement-inc-953508298' target="_blank"><i class='fab fa-linkedin-in' style={{fontSize:'24px', color: '#005da4'}}></i></a>
const linkPaida = <a href='https://www.linkedin.com/in/paidamoyo-perseverence-chawasarira-6b15b5274/' target="_blank"><i class='fab fa-linkedin-in' style={{fontSize:'24px', color: '#005da4'}}></i></a>
const Team = () => {
  return (
    <section class="">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="valuebody mx-auto mb-[60px] max-w-[510px]  text-center">
              <span className=" mb-2 block text-lg font-semibold text-[#005da4] text-[44px]">
               
              </span>
              <h1 className=" valueH1 mb-3 text-3xl font-bold leading-[1.2] text-[#005da4]-900  sm:text-4xl lg:text-[44px]">
                Our Awesome Team
              </h1>
            
            </div>
          </div>
        </div>

        <TransparentTabs/>

        <div className="-mx-24 flex flex-wrap justify-center gap-12">
          <TeamCard
            name="Shepherd"
            profession="President"
            imageSrc={ shepiro1 }
            linkedin= {linkShepherd}
          />
          <TeamCard
            name="Paida"
            profession="Managing Director"
            imageSrc={ paida1 } 
            linkedin= { linkPaida }
          />
          <TeamCard
            name="Ellen"
            profession="Administrator"
            imageSrc= { ellen }
            linkedin= { linkEllen }
            
          />
          {/* <TeamCard
            name="Vimbayi"
            profession="Recruiter"
            imageSrc= { vndoro }
            linkedin= { linkVimbayi }
          /> */}
        </div>
      </div>
    </section>
  );
};

export default Team;

const TeamCard = ({ imageSrc, name, profession, linkedin }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 xl:w-1/4">
        <div className="mx-auto mb-10 w-full max-w-[370px]">
          <div className="relative overflow-hidden rounded-lg">
            <img src={imageSrc} alt="" className="w-full" style={{ height: '32rem'}} />
            <div className="absolute bottom-5 left-0 w-full text-center">
              <div className="relative mx-3 overflow-hidden rounded-lg bg-white  dark:bg-dark-2">
                <h2 className="text-base font-semibold dark:text-[#005da4] pt-1 pb-0">
                  {name}
                </h2>
                <p className="text-md text-body-color dark:text-dark-6">
                  {profession} <br/>
                  {linkedin}
                  
                </p>
                <p className="text-xs text-body-color dark:text-dark-6">
                 
                </p>
                <div>
                  <span className="absolute bottom-0 left-0">
                    <svg
                      width={61}
                      height={30}
                      viewBox="0 0 61 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx={16}
                        cy={45}
                        r={45}
                        fill="#13C296"
                        fillOpacity="0.11"
                      />
                    </svg>
                  </span>
                  <span className="absolute right-0 top-0">
                    <svg
                      width={20}
                      height={25}
                      viewBox="0 0 20 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="0.706257"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 0.706257 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 6.39669 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 12.0881 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="24.3533"
                        r="0.646687"
                        transform="rotate(-90 17.7785 24.3533)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 0.706257 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 6.39669 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 12.0881 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="18.6624"
                        r="0.646687"
                        transform="rotate(-90 17.7785 18.6624)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 0.706257 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 6.39669 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 12.0881 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="12.9717"
                        r="0.646687"
                        transform="rotate(-90 17.7785 12.9717)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 0.706257 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 6.39669 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 12.0881 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="7.28077"
                        r="0.646687"
                        transform="rotate(-90 17.7785 7.28077)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="0.706257"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 0.706257 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="6.39669"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 6.39669 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="12.0881"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 12.0881 1.58989)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="17.7785"
                        cy="1.58989"
                        r="0.646687"
                        transform="rotate(-90 17.7785 1.58989)"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

