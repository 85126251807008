import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Footer, Header } from "../components";
import { profimage } from "../assets";
import ReactMarkdown from "react-markdown";
import MarkdownEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { urls } from "../constants";
import Swal from "sweetalert2";

const AddJobs = () => {
  const [user, setUser] = useState({});
  let company_name = user?.company_name;
  const [industry, setIndustry] = useState([]);
  const userId = localStorage.getItem("user_id");
  const history = useNavigate();

  const fetchLoggedInUser = () => {
    axios
      .get(`${urls.url}/users/${userId}`)
      .then((resp) => {
        setUser(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [job, setJob] = useState({
    job_title: "",
    location: "",
    industry: "",
    description: "",
    qualification: "",
  });

  const fetchAllIndustries = () => {
    axios
      .get(`${urls.url}/industries/`)
      .then((resp) => {
        setIndustry(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addJob = (e) => {
    const updatedJob = {
      ...job,
      company_name: company_name,
    };
    e.preventDefault();
    axios
      .post(`${urls.url}/all-jobs/`, updatedJob)
      .then((resp) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
        });
        history("/my-jobs");
        console.log(resp);
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong!',
        });
        console.warn(err + "ziku error");
      });
  };

  useEffect(() => {
    // fetchLoggedInUser();
    fetchAllIndustries();
  }, []);

  return (
  <div style={{backgroundColor: "white"}}>
      <Header />
      <div >
        <div className="container">
          <div className="row">
            <div className="col-lg-6" style={{padding: "0"}}>
              <img
                style={{  borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px", height: "100%"}}
                src={profimage}
                alt=""
              ></img>
            </div>


            <div className="col-lg-6 addjobimg" style={{padding: "0", borderTopRightRadius: "8px", borderBottomRightRadius: "8px"}}>
              <form
                onSubmit={addJob}
                className="bg-slate-300 shadow-md rounded px-8 pt-6 pb-8 mb-4" style={{ height: "100%" }}
              >
                <h1 className="text-4xl text-center mb-5">Add Job</h1>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="username"
                  >
                    Job Title
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    type="text"
                    placeholder="Job Title"
                    name="job_title"
                    onChange={(e) =>
                      setJob({
                        ...job,
                        job_title: e.target.value,
                      })
                    }
                    value={job.job_title}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Location
                  </label>
                  <input
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="text"
                    placeholder="Location"
                    name="location"
                    onChange={(e) =>
                      setJob({
                        ...job,
                        location: e.target.value,
                      })
                    }
                    value={job.location}
                    required
                  />
                  <p className="text-red-500 text-xs italic">Enter location.</p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Description
                  </label>
                  <MarkdownEditor
                    value={job.description}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        description: e.text,
                      })
                    }
                    style={{
                      height: "200px",
                    }}
                  />
                  <p className="text-red-500 text-xs italic">
                    Enter description.
                  </p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Qualifications
                  </label>
                  <MarkdownEditor
                    value={job.qualification}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        qualification: e.text,
                      })
                    }
                    style={{
                      height: "200px",
                    }}
                  />
                  <p className="text-red-500 text-xs italic">
                    Enter qualification.
                  </p>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Industry
                  </label>
                  <select
                    className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Location"
                    value={job.industry}
                    onChange={(e) =>
                      setJob({
                        ...job,
                        industry: e.target.value,
                      })
                    }
                  >
                    <option value="" disabled>
                      Select an industry
                    </option>
                    {industry?.map((el) => (
                      <option key={el.id} value={el.industry_name}>
                        {el?.industry_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 text-xs italic">Enter Industry.</p>
                </div>

                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Add job
                  </button>
                </div>
              </form>
            </div>

            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AddJobs;
