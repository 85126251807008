import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

export function TransparentTabs() {
  const data = [
    {
      label: "President's Profile",
      value: "trustees",
      desc: `Shepherd Murenje is a versatile professional with a rich background spanning 
      multiple continents. Originally from Zimbabwe, he ventured to Botswana and later pursued studies in Germany and the UK, where he cultivated his passion for engineering and mechanics. With a career specializing in Heavy Goods Vehicle (HGV) maintenance, Shepherd has worked with renowned companies like Downton Haulage, Wincanton Pullmans, and Scania GB Swindon branch. His expertise led him to Canada, where he continued to excel with companies such as URS, AECOM, Ecan, Prairie Tech, and MPI Oil Field Services. Throughout his journey, Shepherd has maintained a commitment to excellence and a drive for continuous growth, making him a valuable asset in any endeavor. His story is one of perseverance, innovation, and unwavering dedication to his craft.`,
    },
    {
      label: "The Wildrose Team",
      value: "team",
      desc: `Wildrose Placement Inc. team is a dedicated group of professionals 
      specializing in connecting top talent with exceptional career opportunities. With a keen focus on various industries including engineering, hospitality, healthcare, and more, our team excels in matching skills with the perfect fit. Backed by a commitment to excellence, innovation, and client satisfaction, we pride ourselves on fostering lasting partnerships and driving success for both candidates and employers alike.`,
    },
    {
      label: "Our Partners",
      value: "partner",
      desc: ` We have amazing partners who helps us realize our dream of delivering to our 
      customers.`,
    },
  ];

  const [expandedIndex, setExpandedIndex] = React.useState(-1);

  const toggleExpansion = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <Tabs value="trustees" className="max-w-[40rem] text-justify px-16">
      <TabsHeader
        className="bg-transparent"
        indicatorProps={{
          className: "bg-gray-900/10 shadow-none !text-gray-900",
        }}
      >
        {data.map(({ label, value }, index) => (
          <Tab key={value} value={value}>
            {label}
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody>
        {data.map(({ value, desc }, index) => (
          <TabPanel key={value} value={value}>
            <div>
              {expandedIndex === index
                ? desc
                : desc.split("\n")[0]} {/* Only display the first line */}
              <br />
              <button className="bg-[#005da4] text-white rounded-md pt-2 pb-2 pl-8 pr-8 hover:bg-[red] mt-2" onClick={() => toggleExpansion(index)}>
                {expandedIndex === index ? "Show Less" : "Show More"}
              </button>
            </div>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
