import React from 'react'
import { Footer, Header } from '../components'

const para = {textAlign: "left"}

function Terms() {
  return (
    
    <div className='bg-white'>
        <Header/>
    <div className='container'>
    <h1>WILDROSE PLACEMENT INC. TERMS & CONDITIONS</h1>


<p style={para}>Welcome to Wildrose Placement Inc. These Terms and Conditions outline the rules and regulations for the use of the Wildrose Placement Inc. website. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Wildrose Placement Inc.'s website if you do not agree to ALL of the terms and conditions stated on this page.</p>

<h2>CONTENTS</h2>
<ul style={para}>
  <li>1. Definitions</li>
  <li>2. Terms of business</li>
  <li>3. Content rights</li>
  <li>4. Termination</li>
  <li>5. Governing law and jurisdiction</li>
  <li>6. Amendment</li>
  <li>7. General Prohibition</li>
  <li>8. Other terms for Wildrose Placement Inc. services and products</li>
  <li>9. Other legal information and notices</li>
  <li>10. Registered Office</li>
</ul>

<h2>1. DEFINITIONS</h2>
<p style={para}>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements:</p>
<ul style={para}>
  <li><strong>Client, You, Your:</strong> refers to you, the person logging on this website and compliant to the Company’s terms and conditions.</li>
  <li><strong>The Company, Ourselves, We, Our, Us:</strong> refer to Wildrose Placement Inc. Party, Parties, or Us refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to prevailing law of Canada. Any use of the above terminology or other words in the singular, plural, capitalization, and/or they/he/she or such are taken as interchangeable and therefore as referring to the same.</li>
</ul>

<h2>2. TERMS OF BUSINESS</h2>
<p style={para}>Each employment assignment or placement arising as a result of an introduction made by us will be subject to Wildrose Placement Inc standard Terms of Business as they are applicable in the circumstances. All prospective employers and clients for whom we arrange assignments or placements will be provided with a copy of the Terms of Business applicable to them at or following registration.</p>

<h2>3. CONTENT RIGHTS</h2>
<p style={para}>The rights in material on this site are protected by international copyright, software and trademark laws and you agree to use this site in a way which does not infringe these rights. You may copy material on this site for your own private or domestic purposes, but no copying for any commercial or business use is permitted.</p>

<h2>4. TERMINATION</h2>
<p style={para}>We may terminate your registration and/or deny you access to the site or any part of it (including any services, or information available on or through the site) at any time in our absolute discretion and without any explanation or notification.</p>

<h2>5. GOVERNING LAW AND JURISDICTION</h2>
<p style={para}>These Terms of Use and all issues regarding this Website and its Content are governed by Canadian law, and shall be subject to the exclusive jurisdiction of the ordinary courts of Canada and each provincial jurisdiction.</p>

<h2>6. AMENDMENTS</h2>
<p style={para}>These Terms of Use may be varied from time to time and your use of this Website is subject to the then current terms each time you access the Website and its Content. Please check these Terms of Use regularly to ensure you are familiar with them.</p>

<h2>7. GENERAL PROHIBITION</h2>
<p>In connection with your Use, you agree NOT to:</p>
<ol style={para} type="I">
  <li>violate any local, state, provincial, and federal rules, regulations and statutes, including, but not limited to, anti-discrimination, or equal opportunity employment laws;</li>
  <li>infringe any intellectual property and privacy rights, including, but not limited to, patent, copyright, trademark, or trade secrets, of any third party;</li>
  <li>upload, post, transmit, or store any material that:
    <ul>
      <li>is unlawful, offensive, defamatory, fraudulent, deceptive, misleading, harmful, threatening, harassing, obscene, or objectionable;</li>
      <li>breaches any of your contractual or confidentiality obligations.</li>
      <li>disrupts or interferes with the normal operations of the Site, such as posting or transmitting viruses, continuous posting of repetitive materials, or posting abnormally large load; or are not permitted by Wildrose Placement Inc, including, but not limited to, any unauthorized advertising materials, unsolicited promotional materials, "junk mail," "spam mail," "chain letters," pyramid schemes, franchises, distributorship, club membership, sales arrangement, or otherwise unacceptable materials;</li>
      <li>violate other's privacy rights or personal rights by abusing the Materials, including, but not limited to, harassing or "stalking" another person, sending unsolicited e-mails, and collecting other's personal information;</li>
      <li>breach or attempt to breach any security measures of the Site;</li>
    </ul>
  </li>
  <li>use any device, process, or mechanism to monitor, retrieve, search, or access, e.g., spider or robot, the Site or any Material without Wildrose Placement Inc’s prior written consent;</li>
  <li>Access or attempt to access any account or login of any third party listed on the Site;</li>
  <li>except as expressly permitted herein and except to the extent that applicable laws prevent Wildrose Placement Inc restraining you from doing so, copy, modify, reproduce, delete, distribute, download, store, transmit, sell, re-sell, publish, reverse engineer, or create derivative works of any Materials, except for materials that have been submitted and owned by you;</li>
  <li>post or submit any inaccurate, false, or incomplete information, such as your resume, biographical data, or employment information;</li>
  <li>impersonate any person or entity;</li>
  <li>forge any header information in any electronic posting or mail; or</li>
  <li>misrepresent yourself, your affiliation with any third party, or your entity.</li>
</ol>

<h2>8. OTHER TERMS FOR WILDROSE PLACEMENT INC. SERVICES AND PRODUCTS</h2>
<ul style={para}>
  <li>Immigration</li>
  <li>Job fairs / Seminars</li>
</ul>

<h2>9. OTHER LEGAL INFORMATION AND NOTICES</h2>
<ul style={para} className='mb-20'>
  <li><a href="https://www.justice.gc.ca/eng/csj-sjc/pl/charter-charte/c27_1.html">Canadian Charter of Rights and Freedoms</a></li>
  <li><a href="https://laws-lois.justice.gc.ca/eng/acts/p-8.6/FullText.html">Privacy Act</a></li>
  <li><a href="https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/">Personal Information Protection and Electronic Documents Act ('PIPEDA')</a></li>
  <li><a href="https://www.pwc.com/ca/en/today-s-issues/compliance-transformed/modern-slavery-reporting-requirements/legal-implications-of-canadas-modern-slavery-act.html">Legal Implications of Canada's Modern Slavery Act</a></li>
</ul>

{/* <h2>10. Credits and Mentions</h2>
<ul className='mb-20'>
  <li><a href="https://www.freepik.com/free-photo/woman-with-curly-hair-smiling_10446162.htm#query=corporate%20black&position=17&from_view=search&track=ais&uuid=288b16ec-c948-4101-9f89-56919d5ac7b3">Image by Racool_studio</a> on Freepik</li>
  <li></li>
</ul> */}

    </div>
    <Footer />
    </div>
  )
}

export default Terms
