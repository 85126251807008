import React from 'react'

function Apply() {
    return (
        <div>

            <div>

                <div
                    style={{
                    "display": "block"
                }}
                    className="mb-10 px-6 md:px-16 py-6">

                    <div class="w-full max-w-xl mx-auto relative">
                    <form className="formJobPost sticky top-0 bg-white p-6" action="" method="post">
                            <h1 class="text-3xl mb-2 text-[#fff]">APPLY NOW!</h1>

                            <div class="job-info border-b-2 py-2 mb-5">
                                {/* <!--    Title      --> */}
                                <div class="mb-4">
                                    <label class="block text-[#fff] text-1 mb-2" for="job-title">Name</label>
                                    <input
                                        class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                        type="name"
                                        id="name"
                                        name="name"
                                        placeholder="Fullname"
                                        autofocus/>
                                </div>

                                {/* <!--     Link to apply     --> */}
                                <div class="mb-4">
                                    <label class="block text-[#fff] text-1 mb-2" for="apply-link">Email-Address</label>
                                    <input
                                        class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="email@example.com"/>
                                </div>

                                <div class="md:flex md:justify-between">
                                    {/* <!--      Job Type      --> */}
                                    <div class="w-full md:w-3/12 mb-4 md:mb-0"></div>

                                    {/* <!--     Location       --> */}

                                </div>

                                {/* <!--    Description      --> */}
                                <div>
                                    <label for="description" class="block text-[#fff] text-1 mb-2">Cover Letter</label>
                                    <textarea name="description" id="description" cols="61" rows="5"></textarea>
                                </div>
                                <div>
                                    <label for="qualifications" class="block text-[#fff] text-1 mb-2">Upload Resume</label>
                                    <input
                                        class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                        type="file"
                                        id="email"
                                        name="email"
                                        placeholder="email@example.com"/>
                                </div>

                                <div class="flex flex-wrap -mx-3"></div>

                            </div>

                            {/* <!--     Submit     --> */}
                            <div>
                                
                                <button
                                    class="bg-[#c02128] hover:bg-[#e62328] text-1 text-white py-3 px-5 rounded"
                                    type="submit">Submit Application</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Apply;