import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer } from './components';
import Jobs from "./pages/Jobs";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from './pages/Signup';
import Reset from './pages/Reset';
import Activate from './pages/Activate';
import NewPassword from './pages/NewPassword';
import MyJobs from './pages/MyJobs';
import About from './pages/About';
import AddJobs from './pages/AddJobs';
import MoreInfo from './pages/MoreInfo';
import IndustryDetails from './pages/IndustryDetails';
import AboutIndustry from './pages/AboutIndustry'
import ContactUs from './components/ContactUs';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Faqs from './pages/Faqs';
import Employers from './pages/Employers';
import President from './components/President';
import JobSeekers from './pages/JobSeekers';
import Values from './components/Values';




function App() {
  return (
<div>
<BrowserRouter>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/my-jobs" element={<MyJobs />} /> 
          <Route path="/add-job" element={<AddJobs/>}/>
          <Route path="/about"element={<About/>}/>
          <Route path="/about-industry"element={<AboutIndustry/>}/>
          <Route path="/candidates" element={<JobSeekers />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/faqs" element={<Faqs/>}/>
          <Route path="/login" element={<Login />} />
          <Route path="/values" element={<Values />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/employers" element={<Employers/>} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/president" element={<President />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/activate/:uuid/:token" element={<Activate />} />
          <Route path="/password/reset/confirm/:uuid/:token" element={<NewPassword />} />
          <Route path="/more-info/:id" element={<MoreInfo />} />
          <Route path="/industry/:id/:name" element={<IndustryDetails />} />
         

          </Routes>
    </BrowserRouter>

</div>
  );
}

export default App;

