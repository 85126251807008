import React from 'react'
import { shepiro } from '../assets';
import Header from './Header';
import Footer from './Footer';


function President() {
  return (
    <div>
    <Header />
    <div className='container'>
      <div className='row'>
        <div className='president col-lg-6'>
            <h2>Shepard Murenje</h2>
            <h4>President</h4>
            <p>
            Shepherd Murenje is a dynamic individual whose journey has taken him across continents, shaping his diverse experiences and enriching his professional expertise.

Originally hailing from Zimbabwe, Shepherd ventured to Botswana in pursuit of new opportunities. His early years in Botswana instilled in him a resilience and adaptability that would serve him well in his future endeavors.

Driven by a passion for learning and a thirst for adventure, Shepherd set his sights on Germany, where he gained admission to the esteemed Karlsruhe Institute of Technology. There, he immersed himself in the world of engineering, laying the foundation for a successful career in the field.

Seeking to broaden his horizons further, Shepherd journeyed to the United Kingdom, where he pursued additional education at Northampton College and Oxford Brookes University. It was during this time in the UK that Shepherd began to explore his passion for mechanics, embarking on a career path that would see him excel in the field of Heavy Goods Vehicle (HGV) maintenance.

With a keen eye for detail and a knack for problem-solving, Shepherd honed his skills as an HGV mechanic at renowned companies such as Downton Haulage, Wincanton Pullmans, and Scania GB Swindon branch. His dedication and expertise earned him a reputation as a reliable and resourceful professional within the industry.

Eager to embark on new adventures, Shepherd made the bold decision to relocate to Canada, where he continued to make waves in the field of engineering and mechanics. Over the years, he lent his talents to prominent companies including URS, AECOM, Ecan, Prairie Tech, and MPI Oil Field Services, leaving an indelible mark on each organization he touched.

Throughout his journey, Shepherd has remained committed to excellence, continually seeking out new challenges and opportunities for growth. His diverse background and multifaceted skill set make him a valuable asset in any endeavor, and his passion for making a positive impact on the world serves as a driving force in all that he does.

As Shepherd continues to carve out his path in the world, he does so with a spirit of determination, innovation, and unwavering dedication to his craft. His story is a testament to the power of perseverance and the limitless potential that lies within each of us.

            </p>

        </div>
        <div className='presPic col-lg-6'>
            <img style={{borderRadius: "5px"}} src={shepiro} alt='presidentImage'/>
        </div>
      </div>
    
    </div>
    <Footer />
    </div>
  )
}

export default President
