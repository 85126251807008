import React from "react";
import { Footer, Header } from "../components";

const para = {textAlign: "left"}



function Privacy() {
  return (
    <div className="bg-white">
      <Header />
      <div class="container">
        <h1>WILDROSE PLACEMENT INC. COOKIES POLICY</h1>

        <h2>CONTENTS</h2>
        <ul style={para}>
          <li>1. Introduction</li>
          <li>2. Categories of cookies used by Wildrose Placement Inc?</li>
          <li>3. How can I disable or delete cookies?</li>
          <li>4. What if you have other questions or complaints?</li>
          <li>5. Will there be updates to this cookie notice?</li>
          <li>6. Online tracking how we respond to do not track signals</li>
        </ul>

        <h2>1. INTRODUCTION</h2>
        <p style={para}>
          <strong>COOKIES:</strong> We employ the use of cookies. By accessing
          Wildrose Placement Inc.'s website, you agreed to use cookies in
          agreement with Wildrose Placement Inc.'s Privacy Policy. License:
          Unless otherwise stated, Wildrose Placement Inc. and/or its licensors
          own the intellectual property rights for all material on Wildrose
          Placement Inc. All intellectual property rights are reserved. You may
          access this from Wildrose Placement Inc. for your personal use
          subjected to restrictions set in these terms and conditions. You must
          not:
        </p>
        <ul style={para}>
          <li>Republish material from Wildrose Placement Inc.</li>
          <li>
            Sell, rent or sub-license material from Wildrose Placement Inc.
          </li>
          <li>
            Reproduce, duplicate, or copy material from Wildrose Placement Inc.
          </li>
          <li>Redistribute content from Wildrose Placement Inc.</li>
        </ul>
        <p>This Agreement shall begin on the date hereof.</p>

        <h2>2. CATEGORIES OF COOKIES USED BY WILDROSE PLACEMENT INC.?</h2>
        <p style={para}>We use functional, analytical, and tracking cookies:</p>
        <ul style={para}>
          <li>
            <strong>Functional Cookies:</strong> We use ‘functional cookies’ for
            the purpose of carrying out or facilitating the transmission of
            communication via the Internet. Furthermore, we use these cookies
            when they are strictly necessary in order to provide our services to
            you, for example, to use a shopping cart or to log in to secure
            areas of our website. Disabling these cookies may affect the
            performance of our website or may make some of the features and
            services unavailable.
          </li>
          <li>
            <strong>Analytical Cookies:</strong> We use ‘analytical cookies’ in
            order to learn and understand how our visitors engage with our
            website. These cookies allow us to improve and optimize our website,
            for example by ensuring that our visitors can easily find the
            information they are looking for. In order to analyze how our
            website is used, we count the number of visitors, the number of
            visits, the number of pages you use or the location from which you
            visit our website. We only use your location if you allow us to do
            so. Analytical cookies also help us measure the performance of our
            advertising campaigns. This allows us to improve the campaigns and
            optimize our website’ content for those who engage with our
            advertising.
          </li>
          <li>
            <strong>Tracking Cookies:</strong> We use ‘tracking cookies’ as a
            way to compile records of the browsing history of our visitors.
            These cookies are, for example, used when we place advertisements on
            other websites and in other apps. We may also use these cookies to
            track which of our advertisements you have already seen, so that you
            will not always see the same banners or content on your social
            media, such as Facebook, LinkedIn, or Instagram.
          </li>
        </ul>

        <h2>3. HOW CAN I DISABLE OR DELETE COOKIES?</h2>
        <p style={para}>
          If you no longer want cookies to be stored on your device, you can
          withdraw your consent at any time by adjusting your browser settings,
          so that your browser refuses all cookies or the cookies from third
          parties. You can also delete the cookies that have already been placed
          on your device. If the cookies are disabled, our website will continue
          to work. However, some parts of our website, such as the shipping tool
          or the tracking and tracing tool of packages, might not work when
          cookies are disabled. Moreover, disabling cookies does not mean that
          you will no longer see advertisements. This only means that
          advertisements will not be adjusted to your interests or that they
          will be repeated more often. If you want to disable cookies from
          specific parties, you can do so via{" "}
          <a href="www.youronlinechoices.com">www.youronlinechoices.com</a>. How
          you manage your cookie settings, including deletion, may differ per
          browser. Please use the “Help” function in your browser. If you need
          more information about your rights in relation to any processing of
          your personal data based on cookies, we refer to our Privacy Notice.
          You can find the link under paragraph 1 of this Cookie Notice.
        </p>

        <h2>4. WHAT IF YOU HAVE OTHER QUESTIONS OR COMPLAINTS?</h2>
        <p style={para}>
          Questions or complaints regarding the use of cookies can be directed
          to Wildrose Placement Inc by using the contact information provided at
          the top of this Cookie Notice.{" "}
          <a href="mailto:info@wildroseplacement.ca">
            info@wildroseplacement.ca
          </a>
        </p>

        <h2>5. WILL THERE BE UPDATES TO THIS COOKIE NOTICE?</h2>
        <p style={para}>
          Wildrose Placement Inc may update this Cookie Notice from time to
          time. If an amendment will have a serious impact, Wildrose Placement
          Inc will endeavor to actively inform you about such amendments.
          Wildrose Placement Inc will always publish an up-to-date Cookie Notice
          on our Websites, indicating the latest amendments.
        </p>

        <h2>6. ONLINE TRACKING HOW WE RESPOND TO DO NOT TRACK SIGNALS</h2>
        <p style={para}>
          Online tracking is the collection of data about an individual’s
          Internet activity used to deliver targeted advertisements and for
          other purposes. Some web browsers (including Safari, Internet
          Explorer, Firefox, and Chrome) incorporate a “Do Not Track” (DNT) or
          similar feature that signals to websites that a visitor does not want
          to have his/her online activity and behavior tracked. If an online
          service elects to respond to a particular DNT signal, the service may
          refrain from collecting certain personal information about the
          browser’s user. Not all browsers offer a DNT option and there is
          currently no industry consensus as to what constitutes a DNT signal.
          For these reasons, many website operators, including Wildrose
          Placement Inc, do not take action to respond to DNT signals. For more
          information about DNT signals contact us on{" "}
          <a href="mailto:info@wildroseplacement.ca">
            info@wildroseplacement.ca
          </a>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
