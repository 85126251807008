import React from 'react'

function Banner() {
  return (
    <div className='' id='rssBlock'>

      <span style={{color: "red"}}>***COMING SOON!!!***COMING SOON!!!***COMING SOON!!!***</span> 
    <p style={{margin: "-9px"}} className="cnnContents">
    
       <span class="marqueeStyle">&nbsp;&nbsp;&nbsp;&nbsp; "Wildrose Placement Inc: Not live for business yet. We're testing, but big things are coming" --- "Testing in progress! Wildrose Placement Inc is under construction. Future opportunities await!" <span className='text-[#e62328]'>***IMPORTANT***</span></span>
       <span class="marqueeStyle2">&nbsp; "Not open for business yet! Wildrose Placement Inc is in test mode—prepare for greatness!" --- "Exciting times ahead! Wildrose is under construction for testing. Your career adventure is brewing!"<span className='text-[#e62328]'>&nbsp;***IMPORTANT***&nbsp;</span>&nbsp;&nbsp;</span>
    </p>
      </div>
  )
}

export default Banner
