import React from 'react';
import { loginpg } from '../assets/index';
import { Link } from 'react-router-dom'

const Summary = () => {
  
      
  return (
    
    <div class="container mt-11">
      <div class="row">
            {/* <div  className='grid grid-cols-3 justify-items-center shadow-lg shadow-[#c02128] mx-auto max-w-screen-xl mb-40'> */}
                <div className='col-lg-6 pb-10 bg-white justify-items-center shadow-lg shadow-[#c02128]' style={{paddingRight: "0"}}>
                    <h3 className='font-bold text-2xl mb-5'></h3>
                    <div className="text-left" style={{padding: "5px"}}>
                        <h1 className="text-2xl font-bold mb-5 text-[#005da4]">Welcome to Wildrose Placement Inc.</h1>
                        <p className="mb-5  increase text-justify">
                            At Wildrose Placement Inc., we specialize in bridging talent with opportunity
                            across diverse sectors. Our passion lies in connecting exceptional professionals
                            with employers seeking excellence.
                        </p>

                        <div className="mb-5">
                            <h2 className="text-2xl font-bold mb-2 text-[#005da4]">Our Services: </h2>

                            <ul className="list-disc list-inside increase">
                                <li className='text-justify'>
                                    <strong>Talent Placement: </strong>
                                    Whether you're seeking top-tier talent or exploring new career opportunities, we're here to facilitate seamless placements that align with your goals.
                                </li><br/>
                                <li className='text-justify'>
                                    <strong>Immigration: </strong>
                                    Our licensed Immigration consultant facilities the visa applications for candidates and LMIA applications for employers.
                                </li><br/> {/* <li>
            <strong>Craftsmanship Brilliance:</strong> Preserving tradition while embracing modernity, our Trades
            Division celebrates artisans and technicians who turn skills into masterpieces in Carpentry, Metal
            Fabrication, Plumbing, and more.
          </li> */}

                                <li className='text-justify'>
                                    <strong>Intergration: </strong>
                                    Our integration services includes but not limited to  Airport transfers, obtaining SIN number, Accommodation, converting foreign drivers’ license where applicable registration with regulatory boards and general settling in Canada
                                </li><br/>

                                <li className='text-justify'>
                                    <strong>Consultancy: </strong>
                                    From HR consulting to talent acquisition strategies, our experts provide valuable insights and solutions to help businesses thrive in today's competitive landscape.
Career Development: Through coaching, resume optimization, and interview preparation, we empower individuals to unlock their full potential and achieve their career aspirations
                                </li>

                                {/* Add more list items as needed */}
                            </ul>
                        </div>

                        <p className="mb-5 increase">
                            {/* Whether you're a professional seeking the next career milestone or a business in
                            pursuit of exceptional talent, Wildrose Placement Inc. is your dedicated
                            partner. Experience expertise, compassion, and innovation in every placement. */}
                        </p>

                        <p className=''>Wildrose Placement Inc, Where Talent Meets Opportunity. </p>
                    </div>
                    
                    <div className='content-center mt-10 '>
                    <Link to='/about-industry'>
                        <button
                            className='bg-[#005da4] text-white text-center w-60 h-10 rounded-lg animate-bounce '>Read More
                    </button>
                    </Link>
                    </div>
                </div>
                <div class='col-lg-6 shadow-lg shadow-gray-900 mr-0 pr-0 rounded-md' style={{paddingLeft: "0"}}>
                    <img 
                        src={loginpg}
                        className='h-full object-full object-center '
                        alt="Hospitality Image"/>
                </div>
            {/* </div> */}
        </div>
        </div>
     
  );

        }
export default Summary
