import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { whiteLogo } from '../assets';
import { useNavigate } from 'react-router-dom';
import { urls } from '../constants';

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [displayedJobs, setDisplayedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllJobs = () => {
    axios
      .get(`${urls.url}/all-jobs/`)
      .then((res) => {
        setJobs(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let nav = useNavigate();

  function hist(id) {
    nav(`more-info/${id}`);
  }

  useEffect(() => {
    fetchAllJobs();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // Shuffle the jobs array
      const shuffledJobs = jobs.sort(() => Math.random() - 0.5);
      // Take the first 4 jobs
      const firstFourJobs = shuffledJobs.slice(0, 4);
      setDisplayedJobs(firstFourJobs);
      setIsLoading(true);
    }
  }, [jobs, isLoading]);

  return (
    <div className='pt-10  '>
    <span className='valuebody'><h1 className='valueH1 text-center text-[#005da4] text-6xl font-semibold pb-2'>
    FEATURED JOBS
      </h1></span>
    

      <div className='pl-10 pb-12'>
        <div className='jobhove justify-items-center grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 mx-auto max-w-screen-xl mt-20 '>
          {displayedJobs.map((el, index) => (
            <div
              key={el.id}
              className={`group group/item singleJob w-[250px] p-[20px] rounded-[10px] shadow-lg shadow-gray-400 hover:bg-blue-500 hover:shadow-lg flex flex-col justify-between slide-and-fade-in animate-fade-in-${index}`}
            >
              <span className='flex justify-between items-center gap-4'>
                <h1 className='text-[20px] font-bold text-[#005da4] group-hover:text-[#fff]'>
                  {el?.job_title}
                </h1>
                <span className='flex items-center text-[#ccc] gap-1 text-xs'>{el?.published_at}</span>
              </span>
              <h6 className='text-[#ccc]'>{el?.location}</h6>
              <p className='text-left text-[13px] text-[#959595] pt-[20px] border-t-[2px] mt-[20px] 
              group-hover:text-[#fff]' dangerouslySetInnerHTML={{__html: el?.description.substring(0, 120)
        }}/>
            
              <div className='flex items-center gap-2'>
                <img src={whiteLogo} className='w-[50%]' alt='Logo' />
                <span className='text-[14px] py-[1rem] block group-hover:text-[#fff]'></span>
              </div>
              <button
                onClick={() => {
                  nav(`/more-info/${el?.id}`);
                }}
                className='border-[2px] rounded-[10px] block p-[10px] w-full text-[14px] font-semibold text-[#005da4] group-hover:text-[#fff] transform transition-transform duration-300 hover:scale-105'
              >
                Apply Now
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
