import React from 'react';
import './Values.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseye } from '@fortawesome/free-solid-svg-icons'; // Importing from solid icons package
import { faHtml5, faCss3, faJs, faGithub } from '@fortawesome/free-brands-svg-icons'; // Importing from brands icons package


function Values() {
  return (
    <div className='valuebody mb-20 mt-10'>
      <h1 className='valueH1'>Wildrose Placement Inc Values</h1>
      <ul className='ulvalues text-left'>
        <li className='pd- ' style={{ '--accent-color': '#60c5a6' }}>
          <div className="icon"><FontAwesomeIcon icon={faBullseye} /></div>
          <div style={{'width': "15rem"}} className="title">Integrity </div>
          <div className="descr text-justify shadow p-2 w-150">Upholding honesty and ethical behavior in all dealings.</div>
        </li>
        <li className='' style={{ '--accent-color': '#f20071' }}>
          <div className="icon"><FontAwesomeIcon icon={faBullseye} /></div>
          <div style={{'width': "15rem"}} className="title ">Excellence</div>
          <div className="descr text-justify shadow p-2 w-150">Striving for the highest quality in products, services, and operations.</div>
        </li>
        <li className='pl-' style={{ '--accent-color': '#FCBA35' }}>
          <div className="icon"><FontAwesomeIcon icon={faBullseye} /></div>
          <div style={{'width': "15rem"}} className="title">Respect</div>
          <div className="descr text-left shadow p- w-150">Valuing diversity, treating everyone with dignity, and fostering an inclusive environment.</div>
        </li>
        <li className='pl-' style={{ '--accent-color': '#DAE438' }}>
          <div className="icon"><FontAwesomeIcon icon={faBullseye} /></div>
          <div style={{'width': "15rem"}} className="title">Innovation</div>
          <div className="descr text-justify shadow p-2 w-150">Encouraging creativity and forward-thinking to adapt and improve.</div>
        </li>
        <li className='pl-' style={{ '--accent-color': '#994D7F' }}>
          <div className="icon"><FontAwesomeIcon icon={faBullseye} /></div>
          <div style={{'width': "15rem"}} className="title">Teamwork</div>
          <div className="descr text-justify shadow p-2 w-150">Collaborating effectively and valuing contributions from all team members.</div>
        </li>
      </ul>
      {/* <div className="credits">
        <a target="_blank" href="https://www.freepik.com/premium-vector/modern-3d-infographic-template-with-5-steps-success_5957879.htm">inspired by</a>
      </div> */}
    </div>
  );
}

export default Values;
