import React, { useState } from 'react';
import DynamicPlaceholder from './DynamicPlaceholder';

const SearchBox = ({ onSearch }) => {
  const placeholderTexts = [
    'Discover your next career milestone with WildroseplacementInc.',
    'Embark on a journey of exciting opportunities, empowered by WildroseplacementInc.',
    'Realize your dream job with precision and care, crafted by WildroseplacementInc.',
    'Uncover incredible job opportunities with the guidance of WildroseplacementInc.'
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  return (
    <div className=" relative">
      <div className='mobilesearch'>
      <label htmlFor="default-search" className="mb-2 text-lg font-medium text-gray-900 sr-only dark:text-white">
        Search
      </label>
      <input
        className="text-black block w-full p-4 pl-10 text-2xl text-gray border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 white:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        type="search"
        placeholder={DynamicPlaceholder({ placeholderTexts })} 
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <button
        type="submit"
        className="text-white absolute top-10 transform -translate-y-1/2 right-0.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-l px-8 py-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        onClick={handleSearch}
      >
        Search
      </button>
      </div>
    </div>
  );
};

export default SearchBox;
