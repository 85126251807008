import React from 'react'
import { Footer, Header, JobsInfo } from '../components'
import Accordions from '../components/Accordions'

const AboutIndustry = () => {
  return (
    <div>
      < Header />
       <Accordions />
      <Footer />
    </div>
  )
}

export default AboutIndustry