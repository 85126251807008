import axios from "axios";
import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { urls } from "../constants";
import {  login1, wildroselogo2 } from "../assets";
import Swal from "sweetalert2";

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });

  let navigate = useNavigate();
  let access_token = "";
  let currentDate = new Date();

  const loginUser = (e) => {
    e.preventDefault();
    axios
      .post(`${urls.url}/urls/jwt/create/`, user)
      .then((resp) => {
        access_token = resp?.data?.access;
        sessionStorage.setItem("access", access_token);
        const decoded = jwtDecode(access_token);
        localStorage.setItem("user_id", decoded?.user_id);
        navigate("/my-jobs");
      })
      .catch((err) => {
        
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${err?.response?.data?.detail}`,
        });
      });
  };

  return (
    <div className="bg-white">
     
      <div className="container mt-0">
        <div className="row">
        <div
  className="col-lg-6 col-md-6"
  style={{
    backgroundImage: `url(${login1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',  // Set the height to 100% of the viewport height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
  }}
>
  {/* Logo image */}
  <Link to="/">
        <img
          src={wildroselogo2}
          alt="Logo, "
          className="img-fluid mx-auto d-block mt-0 mb-0"
          style={{ maxWidth: '50%', position: 'absolute', top: '10%', left: '23%', transform: 'translate(-50%, -50%)' }}
        />
      </Link>

  {/* Content that you want to overlay on the background image */}
  <div style={{ display: 'block' }}>
  <h1 className="col-md-12 text-white">Welcome Back!</h1>
  <p className="col-md-12 text-white">We are glad to see you again!!</p>
</div>

</div>

        
          <div className=" col-lg-6 col-md-6 pt-72">
          {/* <div className=" col-lg-6 col-md-6 col-sm-12- pl-30px" style={{paddingTop: "300px"}}> */}
            <form 
              onSubmit={loginUser}
              className="bg-[#F5F5F5] border-gray-900 shadow-lg rounded px-8 p-5"
            >
              <span className="valuebody"><h1 className="valueH1 text-[#005da4] text-4xl text-center mb-5">Login</h1></span>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-lg font-bold mb-2"
                  htmlFor="username"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="username"
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) =>
                    setUser({
                      ...user,
                      email: e.target.value,
                    })
                  }
                  value={user.email}
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-lg font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="******************"
                  name="password"
                  onChange={(e) =>
                    setUser({
                      ...user,
                      password: e.target.value,
                    })
                  }
                  value={user.password}
                  required
                />
                <p className="text-red-500 text-md italic">Enter password.</p>
              </div>
              <div className="flex justify-center mb-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white text-xl font-bold py-4 px-32 rounded focus:outline-none focus:shadow-outline hover:no-underline"
                  type="submit"
                >
                  Login
                </button>
                
                            
                       
              
                
              </div>
              <Link className='hover:no-underline' to="/signup">
              Need an account <span style={{fontSize: "20px" }} className="text-red-700 hover:no-underline hover:scale-110">Register</span> 
                                {/* <div className='flex justify-center'>
                                    <button className='bg-red-500 hover:bg-blue-700 text-white text-xl font-bold py-6 px-20 rounded focus:outline-none focus:shadow-outline hover:no-underline'>
                                        Register
                                    </button>
                                </div> */}
                            </Link>

                            <Link
                  className="ml-2 inline-block align-baseline font-bold text-md text-blue-500 hover:text-blue-800"
                  to="/reset"
                >
                  Forgot Password?
                </Link>
              
              
            </form>
            <p className="text-center text-gray-500 text-xs">
              &copy;{currentDate.getFullYear()} WildRose Placement Inc. All
              rights reserved.
            </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
