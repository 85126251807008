import React, {useState, useEffect} from 'react'
import {whiteLogo} from '../assets'
import {useParams, useNavigate} from 'react-router'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {Footer, Header} from '../components'
import './IndustryDetails.css'
import {health, engineering} from '../assets'
import {Image} from 'react-bootstrap'
import { urls } from '../constants'

const IndustryDetails = () => {
  const { id, name } = useParams()
  const [job, setJob] = useState([])
  const [industry, setIndustry] = useState({})
  
  const nav = useNavigate()

  const fetchAllJobs = () =>{
    axios.get(`${urls.url}/filter-by-industry-name/?industry=${name}`).then(res=>{
        //console.log(res)
        setJob(res?.data)
    }).catch(err=>{
        console.log(err)
    })
  }


  const fetchAllIndustries = () =>{
    axios.get(`${urls.url}/industry/${id}/`).then(res=>{
      console.log(res)
      
      setIndustry(res?.data)
    }).catch(err=>{
        console.log(err)
    })
  }


  console.log(industry)


  useEffect(()=>{
    fetchAllJobs()
    fetchAllIndustries()
  }, [])


    return (
      <>
      


< Header />


<div className="container">
  <div className="row">
    <div className="col-lg-6">
      <article className="text-info">
        {/* Content inside the article should be wrapped in a div */}
        <div>
          <h2>Welcome to {name}</h2>
          <h3>Discover a World of Opportunities:</h3>
          <p className='inddetails' style={{"fontSize":"20px"}}>
            Unlock unparalleled opportunities in the {name} sector with Wildrose
            Placement Inc. Our dedicated Recruitment Consultants specialize in
            connecting exceptional talent with thriving ventures. Whether you're
            an industry professional ready for the next chapter or an employer
            seeking top-tier {name} talent, we go beyond placement, fostering
            partnerships that drive success and innovation. Discover more about
            the transformative experiences we create.
          </p>
          <Link to="/about-industry">
            <button className="btnmore">More Details</button>
          </Link>
        </div>
      </article>
    </div>
    <div className="col-lg-6">
      <div className="image-wrapper">
      <img className='object-cover' src={industry?.image} alt={job.industry_name} style={{ "clip-path": "polygon(0 0, 100% 0, 100% 100%, 60% 100%)", "margin":"0"}} />
        {/* <img style={{ "clip-path": "polygon(0 0, 100% 0, 100% 100%, 40% 100%)", "margin":"0", "height": "29rem"}} src={engineering} alt={job.industry_name} /> */}
      </div>
    </div>
  </div>
</div>

       
      

<div className='jobhove justify-items-center grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 mx-auto max-w-screen-xl mt-20 mb-20'>
         { job?.map((el, index) =>
      <div className={`group group/item singleJob w-[250px] p-[20px] rounded-[10px] shadow-lg shadow-gray-400 hover:bg-blue-500 hover:shadow-lg flex flex-col justify-between slide-and-fade-in animate-fade-in-${index}`}>
             <span className='flex justify-between items-center gap-4'>
                <h1 className='text-[20px] font-bold text-[#005da4] group-hover:text-[#fff]'>
                  {el?.job_title}
                </h1>
                <span className='flex items-center text-[#ccc] gap-1 text-xs'>{el?.published_at}</span>
              </span>
              <h6 className='text-[#ccc]'>{el?.location}</h6>
              <p className='text-left text-[13px] text-[#959595] pt-[20px] border-t-[2px] mt-[20px] group-hover:text-[#fff]' dangerouslySetInnerHTML={{__html: el?.description.substring(0, 90)}} />
           <div className='flex items-center gap-2'>
                <img src={whiteLogo} className='w-[50%]' alt='Logo' />
                <span className='text-[14px] py-[1rem] block group-hover:text-[#fff]'></span>
              </div>
          <button
                onClick={() => {
                  nav(`/more-info/${el?.id}`);
                }}
                className='border-[2px] rounded-[10px] block p-[10px] w-full text-[14px] font-semibold text-[#005da4] group-hover:text-[#fff] transform transition-transform duration-300 hover:scale-105'
              >
                Apply Now
              </button>
      </div>
         )}
         
</div>      
<Footer />
</>
    
    )
}
    

export default IndustryDetails