// Partners.js
import React from 'react';
import Slider from 'react-slick';
import { magetsi, solvaxion, esmprop, fourhills } from '../assets';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Partners.css';

function Partners() {
  const partnerImages = [magetsi, solvaxion, esmprop, fourhills];

  const settings = {
    infinite: true,
    speed: 20000,
    slidesToShow: 3, // Adjust the number of slides for larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    variableWidth: true,
    centerMode: true, // Add this property to center the current slide
    pauseOnHover: false,
    useTransform: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Show only one slide on smaller screens
        },
      },
    ],
  };
  

  return (
    <div className='classes-sec'>
      <span className='valuebody'><h1 className='valueH1 text-center text-[#005da4] font-bold text-6xl pt-10 pb-20'>Our Partners</h1></span>
      <div className='row classes-carousel pb-20'>
        <Slider {...settings} className='slick-infinite'>
          {partnerImages.map((partner, index) => (
            <div key={index} className='slick-slide flex justify-center items-center pt-12'>
              <div className='classes-col'>
                <div className='class-thumb'>
                  <img src={partner} className="h-28" alt={`partner-${index}`} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Partners;