// DynamicPlaceholder.js
import React, { useEffect, useState } from 'react';

const DynamicPlaceholder = ({ placeholderTexts }) => {
  const [currentText, setCurrentText] = useState('');

  const typeTextEffect = (text, interval = 100) => {
    let index = 0;
    const textInterval = setInterval(() => {
      setCurrentText(text.substring(0, index));
      index++;
      if (index > text.length) {
        clearInterval(textInterval);
        setTimeout(() => {
          setCurrentText('');
        }, 2000);
      }
    }, interval);
  };

  useEffect(() => {
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      const placeholder = placeholderTexts[currentIndex];
      typeTextEffect(placeholder);
      currentIndex = (currentIndex + 1) % placeholderTexts.length;
    }, 10000); // Switch to the next text every 20 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, [placeholderTexts]);

  return currentText;
};

export default DynamicPlaceholder;
