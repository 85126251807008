import React from 'react'
import { Gallery, Header, Hero, Socials, Summary, RandomJobs, Footer, Partners } from '../components'



const Home = () => {
  return (
    <div className='bg-white'>
      <Header/>
      <Hero/>
      {/* <Banner /> */}
      <Summary/>
      <Gallery/>
      <RandomJobs />
      <Partners />
      <Socials/>
      <Footer />
    </div>
  )
}

export default Home