import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { Footer, Header } from '../components';
import '@fortawesome/fontawesome-free/css/all.css';
import { about,shepiro, roots, vision, values, paidamoyo, ellen, vimbayi, glorious, wildroselogo1 } from '../assets/index';
import './About.css'
import AboutTime from '../components/AboutTime'
import Team from '../components/Team';
import Values from '../components/Values';
import '../components/Values.css'


const About = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const delayLoader = setTimeout(() => {
      setLoading(false);
      navigate('/about'); // Navigate using useNavigate
    }, 1000); // Set your desired delay time in milliseconds

    // Clear the timeout on component unmount or when navigating is done
    return () => clearTimeout(delayLoader);
  }, [navigate]);

  return (
    <div className='bg-[#fff] mb-0'>
      
        <div>
        <Header/>
        
        <div className="container">
            <div className="bg-white aboutcol" id='about'>
                <div className="">
                    <div className="row align-items-center">
                        <div className="col-lg-6 separate valuebody" style={{ borderRight: "2px solid #005da4"}}>
                            <h1 className="display-4 pb-2 valueH1">About Us</h1>
                            <p className="lead text-muted mb-0 p-12">Welcome to
                                Wildrose Placement Inc., where excellence meets opportunity, and where the
                                perfect match between talent and need is not just a goal, but our commitment.
                                Established with a vision to redefine recruitment and placement standards,
                                Wildrose Placement Inc. stands as a beacon of unwavering dedication to
                                connecting exceptional talent with thriving opportunities. Our journey is woven
                                with the threads of integrity, innovation, and expertise, creating a tapestry of
                                success stories that define who we are and what we stand for.</p>
                            <p
                                className="lead text-muted"
                                style={{
                                width: '50%'
                            }}>
                                <a href="https://bootstrapious.com/snippets" className="text-muted">
                                    <u/></a>
                            </p>
                        </div>
                        <div className="col-lg-6">

                            <img
                                src={about}
                                className='h-full object-cover object-center'
                                alt="Hospitality"/>
                        </div>
                    </div>
                </div>
            </div>


           < Team />
           <section className="mt-4 container mx-auto ">
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-30'>
          <div className="valuebody"> <h1 className='valueH1'>Mission</h1>
            <div className="bg-gray-100 border-shadow shadow-md shadow-red-300 rounded-lg p-6 md:p-12 hover:scale-125 hover:bg-white hover:shadow-[#005da4]">
              <a href="/" className="">
                <img className="w-50" src={wildroselogo1} alt="wildroselogo"/> 
              </a>
              <p className="font-italic text-muted mb-4  text-justify pt-4">"Wildrose Placement Inc. is dedicated to connecting exceptional talent with opportunities that foster growth and success. Our mission is to provide unparalleled service to both our clients and candidates, ensuring mutual satisfaction and long-term partnerships. We are committed to integrity, professionalism, and innovation in all aspects of our work, striving to exceed expectations and positively impact the communities we serve."</p>
              <br/>
            </div>
            {/* Add more grid items as needed */}
          </div>
          <div className="valuebody"> <h1 className='valueH1'>Vision</h1>
            <div className="bg-gray-100 border-shadow shadow-md shadow-red-300 rounded-lg p-6 md:p-12 hover:scale-125 hover:bg-white hover:shadow-[#005da4]">
              <a href="/" className="">
                <img className="w-50" src={wildroselogo1} alt="wildroselogo" /> 
              </a>
              <p className="font-italic text-muted mb-4 text-justify pt-4">At Wildrose Placement Inc, our vision is to create a world where every individual is valued, finds purpose, fulfillment, and growth through meaningful employment. We envision a society where talents are nurtured, opportunities are abundant, and barriers to success are dismantled. </p>
              <br/>
              <br/><br/><br/><br/><br/>
            </div>
            {/* Add more grid items as needed */}
          </div>
          <div className="valuebody"> <h1 className='valueH1'>Our Roots</h1>
            <div className="bg-gray-100 border-shadow shadow-md shadow-red-300 rounded-lg p-6 md:p-12 hover:scale-125 hover:bg-white hover:shadow-[#005da4]">
              <a href="/" className="">
                <img className="w-50" src={wildroselogo1} alt="wildroselogo" /> 
              </a>
              <p className="font-italic text-justify text-muted mb-4 pt-4">Founded on the principles of
                                transparency and trust, Wildrose Placement Inc. was conceptualized by a group of
                                industry experts who recognized the need for a recruitment agency that goes
                                beyond traditional paradigms. With deep-rooted industry knowledge and an acute
                                understanding of the evolving job market, our founders set out on a mission to
                                bridge the gap between talented professionals and organizations in search of
                                excellence.</p> <br/><br/>
           
            </div>
            {/* Add more grid items as needed */}
          </div>
          </div>
        </section>
        
           < Values />
            {/* <AboutTime/> */}

 
            {/* <ProfileCard/> */}
           

        

            <div>
                


            </div>

           
            
         

            <div
                className="container cacenter"
                style={{
                marginTop: "-200px",
                marginBottom: "-200px"
            }}>
                <div className="carousel">
                    <div className="carousel__face text-[#000]">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        Engineering
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure',
                            objectFit: 'cover'
                        // }}>Information Technology</span>
                        }}></span>
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Healthcare</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Hospitality</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Trucking</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Accounting</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Human Resources</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Service industry</span> */}
                    </div>
                    <div className="carousel__face">
                        <span
                            style={{
                            color: 'azure'
                        }}></span>
                        {/* }}>Trades</span> */}
                    </div>
                </div>


            </div>

        </div>

    </div>
   


<Footer />
    </div>
  );
};

export default About;
