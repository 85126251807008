import React from 'react'
import { Header, Footer, RandomJobs } from '../components'
import Pricing from '../components/Pricing'

export default function JobSeekers() {
  return (
    <>
    <Header />
    <div className=''>
      <h1>Job Seekers</h1>
      <a href='/jobs'>
        <button type="button" class="py-2 px-5 me-2 mb-0 text-lg font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-black dark:hover:bg-gray-700">JOBS!!! JOBS!!! JOBS!!!</button>
        </a>

      <button></button>
        <Pricing/>
        <RandomJobs />
    </div>
    <Footer />
    </>
  )
}

