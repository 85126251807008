import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import './Socials.css';
import { Link } from 'react-router-dom';

const Socials = () => {
  return (
    <div className='text-center mb-40'>

      {/* <h1 className='text-[#423A3A] font-bold text-6xl mb-1 mt- pt-20'>We are social</h1> */}
      <span className='valuebody'><h1 className='valueH1 text-[#005da4] font-bold text-6xl pt-2'>We are social</h1></span>
      <div className='flex flex-wrap justify-center container'>

        <div className="wrapper">
        <div className="button text-[#316FF6] w-1/2 sm:w-1/4">
  <Link to="https://www.facebook.com/people/Wildrose-Placement-INC/61552947688017/?mibextid=eHce3h" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#316FF6' }}>
    <div className="icon">
      <i className="fab fa-facebook-f" />
    </div>
    <span>Facebook</span>
  </Link>
</div>


<div className="button text-[#1DA1F2] w-1/2 sm:w-1/4">
  <Link to="https://twitter.com/WildroseINC01" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#000' }}>
    <div className="icon">
      <i className="fa-brands fa-x-twitter" />
    </div>
    <span>X</span>
  </Link>
</div>

<div className="button text-[#FD1D1D] w-1/2 sm:w-1/4">
  <Link to="https://instagram.com/wildroseplacementinc?utm_source=qr" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#FD1D1D' }}>
    <div className="icon">
      <i className="fab fa-instagram" />
    </div>
    <span>Instagram</span>
  </Link>
</div>

<div className="button text-[#0088cc] w-1/2 sm:w-1/4">
  <Link to="https://www.linkedin.com/company/wildrose-placement/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#0a66c2' }}>
    <div className="icon">
      <i className="fab fa-linkedin-in" />
    </div>
    <span>LinkedIn</span>
  </Link>
</div>

<div className="button text-[#0088cc] w-1/2 sm:w-1/4">
  <Link to="https://t.me/+G0qj66r9suFiZjIx" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#0088cc' }}>
    <div className="icon">
      <i className="fab fa-telegram" />
    </div>
    <span>Telegram</span>
  </Link>
</div>

<div className="button text-[#FF0000] w-1/2 sm:w-1/3">
  <Link to="https://youtube.com/@WildrosePlacementINC.?si=P0r3eFoFp684BJaV" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#FF0000' }}>
    <div className="icon">
      <i className="fab fa-youtube" />
    </div>
    <span>YouTube</span>
  </Link>
</div>

<div className="button w-1/2 sm:w-1/3">
  <Link to="https://www.tiktok.com/@wildroseplacement" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
    <div className="icon">
      <i className="fab fa-tiktok" />
    </div>
    <span>TikTok</span>
  </Link>
</div>

<div className="button text-[#25D366] w-1/2 sm:w-1/3">
  <Link to="https://web.whatsapp.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#25D366' }}>
    <div className="icon">
      <i className="fab fa-whatsapp" />
    </div>
    <span>WhatsApp</span>
  </Link>
</div>

        </div>

      </div>
    </div>
  );
};

export default Socials;
