import React, { useState } from 'react';
import './Accordions.css';

const Accordion = ({ title, content, expand, onClick }) => (
  <div>
    <dt className={expand ? 'title is-expanded' : 'title'} onClick={onClick}>
      {title}
    </dt>
    <dd className={expand ? 'content is-expanded' : 'content'}>
      <div className="inner-content" dangerouslySetInnerHTML={{ __html: content }} onClick={onClick}></div>
    </dd>
  </div>
);

const Accordions = () => {
  const [accordionState, setAccordionState] = useState({
    block1: true,
    block2: false,
    block3: false,
    block4: false,
    block5: false,
    block6: false,
    block7: false,
    block8: false,
    block9: false,
    block10: false,
  });

  const toggle = (index) => () => {
    setAccordionState((prevState) => ({
      ...prevState,
      [`block${index}`]: !prevState[`block${index}`],
    }));
  };

  const toggleExpand = (expand = false) => () => {
    setAccordionState({
      block1: expand,
      block2: expand,
      block3: expand,
      block4: expand,
      block5: expand,
      block6: expand,
      block7: expand,
      block8: expand,
      block9: expand,
      block10: expand,
    });
  };

  const accordionList = [
    {
      title: 'Engineering',
      content: `<section>
        <h2>About Us</h2>
        <p>At Wildrose Placement Inc., we don't just fill positions; we engineer
          careers. In the heart of Canada's dynamic engineering landscape, our Engineering
          Sector stands as a beacon of innovation, connecting exceptional engineers with
          opportunities that match their unparalleled skills. We specialize in bridging
          the significant labor shortage gap in Canada by bringing together world-class
          talent and visionary companies.</p>
        <h2>Our Expertise</h2>
        <p>Wildrose Engineering Department is more than just a staffing agency; we are
          architects of careers and pioneers of talent acquisition. Our dedicated team of
          Recruitment Consultants, backed by a legacy of excellence, ensures that the
          right skills meet the right opportunities.</p>
        <h2>What Sets Us Apart</h2>
        <h2>Engineering Excellence</h2>
        <p>Our portfolio includes an array of engineering roles, each representing the
          pinnacle of innovation and expertise.
        </p>
        <h3>Mechanical &amp; Industrial Engineering:</h3>
        <h2>Your Dream Career Awaits</h2>
        <p>Exceptional engineers and visionary employers, your match is here. Wildrose
          Placement Inc. is where careers take flight, and innovations find a home. If
          you're an engineer ready to redefine your limits or an employer seeking
          unparalleled expertise, contact us today.</p>
      </section>`,
    },
    { 
      title: 'Hospitality', 
      content: `
      <p>Elevating Hospitality Experiences</p>
      </header>
      <section>
      <h2>Welcome to Wildrose Placement Inc.</h2>
      <p>
        Where we don't just provide recruitment solutions; we curate exceptional experiences within the heart of the hospitality industry.
        In partnership with visionary employers, our Hospitality Department specializes in expert recruitment across various sectors,
        transforming ordinary service into extraordinary moments.
      </p>
  
      <h2>Our Passion for Hospitality</h2>
      <p>
        At Wildrose, hospitality is not just an industry; it's our passion. We embark on a diligent search to discover individuals
        with exceptional personalities who comprehend the essence of excellence, luxury, and unparalleled customer service.
        Our candidates embody perfection from the very first interaction, setting the stage for an exceptional guest experience.
        With unwavering support from our clients, we empower candidates to realize their full potential, ensuring every interaction
        is nothing short of magical.
      </p>
  
      <h2>Sectors We Specialize In:</h2>
      <ul>
        <li>Food & Beverage: Where culinary artistry meets exceptional service.</li>
        <li>Accommodation: Creating unforgettable stays in luxurious settings.</li>
        <li>Travel & Tourism: Guiding journeys and crafting memorable adventures.</li>
        <li>Entertainment and Recreation: Elevating leisure moments to cherished memories.</li>
      </ul>
  
      <h2>Why Choose Wildrose Placement Inc.</h2>
      <p>
        <strong>Exceptional Candidates:</strong> We meticulously handpick candidates who not only meet the skill requirements
        but also embody the spirit of hospitality, ensuring your guests receive a warm and genuine welcome.
      </p>
      <p>
        <strong>Unparalleled Support:</strong> Our clients form the cornerstone of our success. With your support, we nurture talent,
        allowing them to flourish and create experiences that resonate with your guests.
      </p>
  
      <h2>Realize Your Potential</h2>
      <p>
        If you're seeking your next position in hospitality or are in search of skilled hospitality professionals, look no further.
        Wildrose Placement Inc. is your gateway to a world where every interaction is an opportunity to create lasting memories.
      </p>
  
      <h2>Hospitality Excellence Awaits</h2>
      <p>
        Join hands with us to elevate your hospitality journey. Whether you're a passionate professional ready for your next adventure
        or a discerning employer seeking excellence, our team is here to make it happen.
      </p>
      <p>
        <strong>Elevate Every Moment. Wildrose Placement Inc.</strong>
      </p>
  
      <h2>Contact a member of our team today</h2>
      <p>and let the extraordinary experiences begin.</p>
    </section>
      `,
    },
    {
      title: 'Trucking',
      content: `
      <p>Driving Logistics Excellence</p>
      <section>
   
    <p>
      Where the wheels of industry turn smoothly in the Trucking Sector. In the world of logistics, our Trucking Division
      stands as a beacon of reliability, connecting businesses with skilled drivers and logistics experts who not only transport
      goods but also uphold the reputation of your brand through dependable services.
    </p>

    <h2>Transporting Goods, Building Trust</h2>
    <p>Within the realm of Trucking, we specialize in various facets:</p>
    <ul>
      <li>Professional Drivers: Navigating roads with expertise, ensuring timely deliveries with a focus on safety.</li>
      <li>Logistics Management: Optimizing routes, schedules, and cargo handling for streamlined operations.</li>
      <li>Fleet Maintenance: Ensuring vehicles are in top condition, minimizing downtime and maximizing efficiency.</li>
      <li>Regulatory Compliance: Navigating complex regulations, ensuring seamless cross-border transportation.</li>
    </ul>

    <h2>Our Commitment</h2>
    <p>
      Trucking is not just about shipments; it's about reliability. We select professionals whose dedication goes beyond
      the steering wheel. Our candidates are not just drivers; they are custodians of your cargo, ensuring every delivery
      is not just on time but exceeds expectations.
    </p>

    <h2>Why Partner with Us</h2>
    <p>
      <strong>Reliability Guaranteed:</strong> We identify individuals whose track record showcases not just skills but also
      a commitment to dependable service.
    </p>
    <p>
      <strong>Strategic Alliances:</strong> Our clients are not just businesses; they are guardians of their brand reputation.
      Together, we build logistics frameworks where every delivery becomes a testament to trust and reliability.
    </p>

    <h2>Your Gateway to Logistics Excellence</h2>
    <p>
      Whether you're a seasoned driver seeking a reputable carrier or a business in need of dependable logistics solutions,
      Wildrose Placement Inc. is your trusted partner in driving success.
    </p>

    <h2>Where Reliability Meets the Road</h2>
    <p>
      Join us in shaping logistics solutions where every mile is a testament to trust. For drivers seeking reputable employers
      and businesses aspiring for dependable logistics, let’s forge a path where every delivery is a promise kept.
    </p>

    <p>
      <strong>Empowering Logistics. Wildrose Placement Inc.</strong>
    </p>

    <h2>Contact us today</h2>
    <p>and let reliability define your logistics operations.</p>
  </section>


      `,
    },
    {
      title: 'Information Technology',
      content: `
      <p>Empowering Technological Innovation</p>
      <section>
    <p>
      Where the future is coded and technology meets creativity in the IT Sector. In the world of digital innovation, our IT
      Division stands as a stronghold of expertise, connecting businesses with skilled developers, engineers, and tech
      specialists who not only build software but also craft solutions that define the digital landscape.
    </p>

    <h2>Coding Futures, Crafting Solutions</h2>
    <p>Within the realm of IT, we specialize in various domains:</p>
    <ul>
      <li>Software Development: Creating applications that resonate with user experiences, ensuring functionality meets creativity.</li>
      <li>Cybersecurity: Safeguarding digital assets, ensuring businesses remain resilient against cyber threats.</li>
      <li>Data Science: Analyzing data for insights, shaping strategic decisions through informed analytics.</li>
      <li>Cloud Computing: Optimizing operations, enhancing scalability, and ensuring seamless data accessibility.</li>
    </ul>

    <h2>Our Commitment</h2>
    <p>
      IT is not just about technology; it's about innovation. We identify professionals whose expertise is not limited to code
      but extends to imaginative problem-solving. Our candidates are not just developers; they are architects of digital futures,
      ensuring every software solution transforms businesses.
    </p>

    <h2>Why Partner with Us</h2>
    <p>
      <strong>Innovation Unleashed:</strong> We identify individuals whose creativity is matched only by their technical prowess,
      ensuring every digital solution is groundbreaking.
    </p>
    <p>
      <strong>Strategic Collaborations:</strong> Our clients are not just businesses; they are pioneers of digital transformation.
      Together, we build IT frameworks where every line of code becomes a step toward innovation.
    </p>

    <h2>Your Gateway to Digital Success</h2>
    <p>
      Whether you're a tech professional seeking cutting-edge projects or a business in need of transformative IT solutions,
      Wildrose Placement Inc. is your trusted partner in shaping digital landscapes.
    </p>

    <h2>Where Innovation Meets Code</h2>
    <p>
      Join us in crafting digital ecosystems where every click opens a door to innovation. For tech professionals seeking groundbreaking
      projects and businesses aspiring for digital evolution, let’s embark on a journey where every code builds a digital legacy.
    </p>

    <p>
      <strong>Empowering Digital Frontiers. Wildrose Placement Inc.</strong>
    </p>

    <h2>Contact us today</h2>
    <p>and let innovation define your technological journey.</p>
  </section>
      `,
    },
    {
      title: 'Human Resources',
      content: `
      <h2>Nurturing HR Brilliance</h2>
      <section>
      <h3>Step into the world of strategic talent management</h3>
      <p>
        with Wildrose Placement Inc. In the realm of Human Resources, we are not just recruiters; we are architects of thriving
        workplaces. Our HR Division specializes in connecting businesses with HR professionals who transform challenges into
        opportunities, driving organizational success.
      </p>
  
      <h2>Crafting Careers, Elevating Companies</h2>
      <p>Within the tapestry of HR, we foster excellence in key domains:</p>
      <ul>
        <li>Recruitment & Talent Acquisition: Identifying talents that align perfectly with your organizational ethos and objectives.</li>
        <li>Training & Development: Nurturing skills, fostering growth, and cultivating future leaders.</li>
        <li>Employee Relations: Creating harmonious work environments where collaboration and productivity flourish.</li>
        <li>Compensation & Benefits: Designing packages that attract top-tier talent and retain invaluable employees.</li>
      </ul>
  
      <h2>Our Promise</h2>
      <p>
        Human Resources is more than managing people; it's about creating vibrant, purpose-driven teams. We seek professionals whose
        expertise is matched only by their passion. Our candidates are not just HR experts; they are catalysts of positive change,
        ensuring every employee experience is enriching.
      </p>
  
      <h2>Why Choose Us</h2>
      <p>
        <strong>Strategic Insight:</strong> We identify individuals who understand the strategic role of HR, ensuring human capital aligns
        seamlessly with organizational goals.
      </p>
      <p>
        <strong>Transformative Partnerships:</strong> Our clients are not just businesses; they are pioneers of workplace
        transformation. Together, we build environments where innovation and collaboration thrive.
      </p>
  
      <h2>Your Partner in HR Excellence</h2>
      <p>
        Whether you're an HR professional ready to make an impact or an organization in pursuit of HR brilliance, Wildrose Placement
        Inc. invites you to redefine HR practices.
      </p>
  
      <h2>Where Potential Blossoms</h2>
      <p>
        Join us in creating workplaces where employees not only excel but also find fulfillment. For HR professionals seeking
        meaningful careers and organizations committed to HR excellence, let's shape a future where every employee's journey is a
        success story.
      </p>
  
      <p>
        <strong>Elevating Workplaces. Wildrose Placement Inc.</strong>
      </p>
  
      <h2>Contact us today</h2>
      <p>and let transformative HR experiences define your organization.</p>
    </section>
  

      `,
    },
    {
      title: 'Healthcare',
      content: `
      <p>Empowering Healthcare Excellence</p>
      <section>
    <h2>Enter the realm of compassionate care</h2>
    <p>
      and unparalleled professionalism at Wildrose Placement Inc. In the heart of the healthcare landscape, our Healthcare
      Division stands as a beacon of healing, connecting dedicated healthcare professionals with institutions that prioritize
      patient well-being.
    </p>

    <h2>Fostering Health, Cultivating Careers</h2>
    <p>Within the intricate tapestry of healthcare, we nurture excellence in diverse sectors:</p>
    <ul>
      <li>Medical Practitioners: Guiding patients to recovery, one diagnosis at a time, with expertise and empathy.</li>
      <li>Nursing & Allied Health: Providing skilled and compassionate hands that offer solace and support to those in need.</li>
      <li>Healthcare Administration: Ensuring the seamless functioning of healthcare facilities, enhancing patient experiences.</li>
      <li>Mental Health & Counseling: Offering a safe haven and empathetic ears, fostering emotional well-being and mental resilience.</li>
    </ul>

    <h2>Our Commitment</h2>
    <p>
      Healthcare is more than a profession; it’s a calling. We meticulously select professionals whose dedication mirrors the
      values of your institution. Our candidates are not just healthcare providers; they are beacons of hope, ensuring every
      patient receives care that goes beyond the clinical realm.
    </p>

    <h2>Why Trust Us</h2>
    <p>
      <strong>Exceptional Talent:</strong> We identify individuals whose expertise is matched only by their kindness, ensuring
      patients receive not just treatment, but also understanding and comfort.
    </p>
    <p>
      <strong>Long-lasting Partnerships:</strong> Our clients are not just partners; they are custodians of well-being. Together, we
      build healing spaces, forging relationships that stand resilient in the face of challenges.
    </p>

    <h2>Your Journey to Healing</h2>
    <p>
      Whether you're a healthcare professional seeking to make a meaningful impact or an institution striving for excellence,
      Wildrose Placement Inc. invites you to redefine the future of healthcare.
    </p>

    <h2>Where Healing is a Promise</h2>
    <p>
      Join us in crafting a legacy of care and compassion. For those who seek healing and those who provide it, let’s shape a future
      where every patient feels valued and supported.
    </p>

    <p>
      <strong>Empowering Wellness. Wildrose Placement Inc.</strong>
    </p>

    <h2>Contact us today</h2>
    <p>and let superior patient care define your institution.</p>
  </section>


      `,
    },
    {
      title: 'Trades',
      content: `
     
      <section id=trades>
      <p>Crafting Craftsmanship Excellence</p>
    <h2>Welcome to Wildrose Placement Inc.</h2>
    <p>
      where traditional skills meet modern mastery in the Trades Sector. In the realm of craftsmanship, our Trades Division stands
      as a bastion of expertise, connecting businesses with skilled artisans, technicians, and tradespeople who not only preserve
      the essence of traditional trades but also innovate with contemporary techniques.
    </p>

    <h2>Preserving Heritage, Building Futures</h2>
    <p>Within the realm of Trades, we specialize in diverse disciplines:</p>
    <ul>
      <li>Carpentry & Woodworking: Crafting timeless pieces, from classic furniture to intricate architectural designs.</li>
      <li>Metal Fabrication: Molding metal into functional and artistic marvels, bridging tradition with innovation.</li>
      <li>Plumbing & Electrical: Ensuring homes and businesses function seamlessly with expert plumbing and electrical solutions.</li>
      <li>Masonry & Restoration: Preserving historical structures and creating enduring architectural wonders.</li>
    </ul>

    <h2>Our Commitment</h2>
    <p>
      Trades are not just skills; they are legacies. We identify professionals whose hands bear the mark of expertise and passion.
      Our candidates are not just tradespeople; they are custodians of heritage, ensuring every creation is not just functional
      but a work of art.
    </p>

    <h2>Why Partner with Us</h2>
    <p>
      <strong>Craftsmanship Preserved:</strong> We select individuals whose commitment to traditional techniques is blended seamlessly
      with a spirit of innovation.
    </p>
    <p>
      <strong>Heritage Revitalized:</strong> Our clients are not just businesses; they are preservers of craftsmanship heritage.
      Together, we build trades environments where every creation speaks volumes about tradition and modernity.
    </p>

    <h2>Your Gateway to Craftsmanship Excellence</h2>
    <p>
      Whether you're a skilled artisan seeking appreciation for your craft or a business in search of timeless tradespeople,
      Wildrose Placement Inc. is your trusted partner in crafting masterpieces.
    </p>

    <h2>Where Artistry Meets Innovation</h2>
    <p>
      Join us in shaping a world where every creation is not just functional but a testament to skill and creativity. For artisans
      seeking recognition and businesses aspiring for craftsmanship brilliance, let’s craft a narrative where every piece is a
      masterpiece.
    </p>

    <p>
      <strong>Empowering Craftsmanship. Wildrose Placement Inc.</strong>
    </p>

    <h2>Contact us today</h2>
    <p>and let artistry define your trades endeavors.</p>
  </section>

      `,
    },
    {
      title: 'Accounting',
      content: `
        <div style="background-color: #e0e0e0;">
          <p>HTML and CSS content for Accordion 2</p>
          <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
            <!-- More content -->
          </div>
        </div>
      `,
    },
    // {
    //   title: 'Automotive Industry',
    //   content: `
    //     <h2 style="text-align: center; color: blue;">HTML and CSS content for Accordion 3</h2>
    //     <div>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
    //       <!-- More content -->
    //     </div>
    //   `,
    // },


    // ... other accordion items
  ];

  return (
    <div className="container mb-60">
      <h1>Wildrose Placement Inc Sectors</h1>
      <button type="button" className="btn1 bg-blue-500 hover:bg-blue-700 text-[#005da4] font-bold py-3 px-6 rounded-lg text-xl" onClick={toggleExpand(true)}>
        Expand All
      </button>
      <button type="button" className="btn1 bg-blue-500 hover:bg-blue-700 text-[#005da4] font-bold py-3 px-6 rounded-lg text-xl" onClick={toggleExpand()}>
        Collapse All
      </button>
      <dl className="accordion">
        {accordionList.map((item, index) => (
          <Accordion
            key={index}
            title={item.title}
            content={item.content}
            onClick={toggle(index + 1)}
            expand={accordionState[`block${index + 1}`]}
          />
        ))}
      </dl>
    </div>
  );
};

export default Accordions;