import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Footer, Header} from "../components";
import axios from "axios";
import {urls} from "../constants";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router'




const MoreInfo = () => {
    let {id} = useParams();
    const [job,
        setJob] = useState({});

    const [full_name,
        setFullName] = useState("");
    const [email,
        setEmail] = useState("");
    const [cover_letter,
        setCover_letter] = useState("");
    const [upload_resume,
        setUpload_resume] = useState(null);
    const handleFullNameChange = (event) => {
        setFullName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCoverLetterChange = (event) => {
        setCover_letter(event.target.value);
    };

    const handleUploadResume = (event) => {
        setUpload_resume(event.target.files[0]);
    };

    let nav = useNavigate()

    const handleSubmit = async (event) => {
  event.preventDefault();
  let company_name = job?.company_name;
  const formData = new FormData();
  formData.append("full_name", full_name);
  formData.append("email", email);
  formData.append("cover_letter", cover_letter);
  formData.append("upload_resume", upload_resume);
  formData.append("company_name", company_name);

  try {
    const response = await axios.post(`${urls.url}/candidates/`, formData);
    Swal.fire({ icon: 'success', title: 'Success', text: 'Form submitted successfully!' });
    console.log(response.data);
    // Redirect to the home page
    nav('/jobs');
  } catch (error) {
    // Handle error, e.g., display an error message
    Swal.fire({ icon: 'error', title: 'Error', text: 'Error while uploading!' });
    console.error(error);
  }
};


    function fetchJobs() {
        axios
            .get(`${urls.url}/job/${id}/`)
            .then((res) => {
                console.log(res);
                setJob(res
                    ?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    

    useEffect(() => {
        fetchJobs();
    }, []);

    return (
        <div className="bg-white mb-20">
            <Header/>

            <div className="container mb-20">
                <div
                    className="row mb-40"
                    style={{
                    position: "sticky",
                    paddingBottom: "100px"
                }}>
                    <div className="col-lg-12 mb-20 text-[#005da4] valuebody">
                        <h1 className="valueH1">
                            Apply for your dream job</h1>
                    </div>
                    <div
                        style={{
                        textAlign: "left"
                    }}
                        className=" col-lg-7 job-text-box">
                        <p className="jobdescription heading">
                            <h2
                                dangerouslySetInnerHTML={{
                                __html: job
                                    ?.job_title
                            }}></h2>
                        </p>
                        <h3>
                            <p
                                className="jobdescription"
                                dangerouslySetInnerHTML={{
                                __html: job
                                    ?.industry_name
                            }}></p>
                            <p
                                className=" jobdescription"
                                dangerouslySetInnerHTML={{
                                __html: job
                                    ?.location
                            }}></p>
                           <p
    className="jobdescription"
    dangerouslySetInnerHTML={{
        __html: "Wildrose Placement Inc."
    }}
></p>
                        </h3>
                        <br/>
                        <h4>
                            <p>Job Description:</p>
                        </h4>
                        <h6>
                            <p
                                className=" jobqual"
                                dangerouslySetInnerHTML={{
                                __html: job
                                    ?.description
                            }}></p>
                            <br/>
                            <h4>
                                <p>Qualifications:</p>
                            </h4>
                            <p
                                className=" jobqual"
                                dangerouslySetInnerHTML={{
                                __html: job
                                    ?.qualification
                            }}></p>
                        </h6>
                    </div>

                    <div className="col-lg-5">
                        <div>
                            <div
                                style={{
                                display: "",
                                position: ""
                            }}
                                className="mb-10 px-2 md:px-16 py-0">
                                <div class="w-full max-w-xl mx-auto">
                                    <form className="formJobPost" onSubmit={handleSubmit}>
                                        <h1 class="text-3xl mb-2 text-[#fff]">APPLY NOW</h1>

                                        <div class="job-info border-b-2 py-2 mb-5">
                                            {/* <!--    Title      --> */}
                                            <div class="mb-4">
                                                <label class="block text-[#fff] text-1 mb-2" for="job-title">
                                                    Name
                                                </label>
                                                <input
                                                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                                    type="name"
                                                    id="name"
                                                    name="full_name"
                                                    onChange={handleFullNameChange}
                                                    value={full_name}
                                                    placeholder="Fullname"
                                                    autofocus/>
                                            </div>

                                            {/* <!--     Link to apply     --> */}
                                            <div class="mb-4">
                                                <label class="block text-[#fff] text-1 mb-2" for="apply-link">
                                                    Email-Address
                                                </label>
                                                <input
                                                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    placeholder="email@example.com"
                                                    onChange={handleEmailChange}
                                                    value={email}/>
                                            </div>

                                            <div class="md:flex md:justify-between">
                                                {/* <!--      Job Type      --> */}
                                                <div class="w-full md:w-3/12 mb-4 md:mb-0"></div>

                                                {/* <!--     Location       --> */}
                                            </div>

                                            {/* <!--    Description      --> */}
                                            <div>
                                                <label for="description" class="block text-[#fff] text-1 mb-2">
                                                    Cover Letter
                                                </label>
                                                <textarea
                                                    style={{
                                                    borderRadius: "5px",
                                                    borderColor: "red"
                                                }}
                                                    name="cover_letter"
                                                    id="description"
                                                    cols="61"
                                                    rows="7"
                                                    onChange={handleCoverLetterChange}
                                                    value={cover_letter}></textarea>
                                            </div>
                                            <div>
                                                <label for="qualifications" class="block text-[#fff] text-1 mb-2">
                                                    Upload Resume
                                                </label>
                                                <input
                                                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                                                    type="file"
                                                    id="email"
                                                    name="upload_resume"
                                                    onChange={handleUploadResume}/>
                                            </div>

                                            <div class="flex flex-wrap -mx-3"></div>
                                        </div>

                                        {/* <!--     Submit     --> */}
                                        <div>
                                            <button
                                                // onClick={handleSubmit}
                                                class="bg-[#c02128] hover:bg-[#e62328] text-1 text-white py-3 px-5 rounded"
                                                type="submit">
                                                Submit Application
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*
      {job?.job_title}

      {job?.industry_name}

      {job?.location}

      {job?.company_name}

      {job?.description}

      {job?.qualification} */}
            <Footer/>
        </div>
    );
};

export default MoreInfo;
